.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: #fff;
    border-radius: 12px;
    width: 90%;
    max-width: 800px;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    animation: modalAppear 0.3s ease-out;
  }
  
  @keyframes modalAppear {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  .modal-header {
    padding: 20px 24px;
    border-bottom: 1px solid #e2e8f0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
  }
  
  .modal-header h2 {
    font-size: 1.5rem;
    color: #333;
    margin: 0;
  }
  
  .close-btn {
    background: none;
    border: none;
    font-size: 1.8rem;
    color: #666;
    cursor: pointer;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    transition: all 0.2s ease;
  }
  
  .close-btn:hover {
    background-color: #f0f0f0;
    color: #333;
  }
  
  .modal-body {
    padding: 24px;
  }
  
  .order-section {
    margin-bottom: 32px;
  }
  
  .order-section h3 {
    font-size: 1.2rem;
    color: #333;
    margin-bottom: 16px;
    padding-bottom: 8px;
    border-bottom: 2px solid #8fc760;
  }
  
  .info-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
  }
  
  .info-item {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .info-item label {
    font-size: 0.9rem;
    color: #666;
    font-weight: 500;
  }
  
  .info-item span {
    font-size: 1rem;
    color: #333;
    font-weight: 600;
  }
  
  .items-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .order-item-detail {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px;
    background-color: #f8f9fa;
    border-radius: 8px;
    transition: transform 0.2s ease;
  }
  
  .order-item-detail:hover {
    transform: translateX(4px);
  }
  
  .item-info {
    display: flex;
    flex-direction: column;
    gap: 4px;
  }
  
  .item-name {
    font-weight: 600;
    color: #333;
  }
  
  .item-quantity {
    font-size: 0.9rem;
    color: #666;
  }
  
  .item-price {
    font-weight: 600;
    color: #8fc760;
  }
  
  /* Scrollbar styling */
  .modal-content::-webkit-scrollbar {
    width: 8px;
  }
  
  .modal-content::-webkit-scrollbar-track {
    background: #f0f0f0;
    border-radius: 4px;
  }
  
  .modal-content::-webkit-scrollbar-thumb {
    background: #8fc760;
    border-radius: 4px;
    border: 2px solid #f0f0f0;
  }
  
  .modal-content::-webkit-scrollbar-thumb:hover {
    background: #75b04a;
  }
  
  /* Responsive adjustments */
  @media (max-width: 768px) {
    .modal-content {
      width: 95%;
      margin: 20px;
    }
  
    .info-grid {
      grid-template-columns: 1fr;
    }
  }