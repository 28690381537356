/* CouponModal.css */

.coupon-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Soft overlay for elegance */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
    backdrop-filter: blur(6px); /* Subtle blur effect for Apple-like quality */
  }
  
  .coupon-modal {
    background: linear-gradient(145deg, #f7f7f7, #ffffff); /* Soft gradient for depth */
    border-radius: 16px;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.15); /* Smooth shadow for depth */
    width: 90%;
    max-width: 400px;
    text-align: center;
    padding: 2rem;
    position: relative;
    animation: fadeIn 0.3s ease-in-out;
    font-family: 'Helvetica Neue', sans-serif; /* Clean Apple-like font */
  }
  
  .coupon-modal-header {
    font-size: 2rem;
    color: #4caf50; /* Signature Mellow Heights green */
    font-weight: 700;
    margin-bottom: 1.5rem;
    text-transform: uppercase;
    letter-spacing: 2px;
  }
  
  .coupon-modal-text {
    font-size: 1rem;
    color: #555;
    line-height: 1.8;
    margin-bottom: 2rem;
    font-weight: 400;
  }
  
  .coupon-modal-input-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    margin-bottom: 1.5rem;
  }
  
  .coupon-modal-input {
    width: 92%;
    padding: 0.9rem;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    font-size: 1rem;
    color: #333;
    background-color: #f9f9f9; /* Light gray background for a modern touch */
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
  }
  
  .coupon-modal-input:focus {
    outline: none;
    border-color: #4caf50; /* Green border on focus */
    box-shadow: 0 0 6px rgba(76, 175, 80, 0.3); /* Subtle glow effect */
  }
  
  .coupon-modal-button {
    background-color: #4caf50; /* Signature green */
    color: #fff;
    font-size: 1.1rem;
    font-weight: 600;
    padding: 0.9rem 1.5rem;
    border: none;
    border-radius: 12px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .coupon-modal-button:hover {
    background-color: #43a047; /* Slightly darker green on hover */
    transform: translateY(-2px); /* Subtle lift effect */
  }
  
  .coupon-modal-button:active {
    background-color: #388e3c; /* Even darker green on click */
    transform: translateY(0);
  }
  
  .coupon-modal-close {
    position: absolute;
    top: 1rem;
    right: 1rem;
    background: none;
    border: none;
    font-size: 1.5rem;
    color: #999;
    cursor: pointer;
    transition: color 0.2s ease;
  }
  
  .coupon-modal-close:hover {
    color: #333; /* Darker close button on hover */
  }
  
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: scale(0.95);
    }
    to {
      opacity: 1;
      transform: scale(1);
    }
  }
  
  @media (max-width: 768px) {
    .coupon-modal {
      padding: 1.5rem;
    }
  
    .coupon-modal-header {
      font-size: 1.8rem;
    }
  
    .coupon-modal-text {
      font-size: 0.95rem;
    }
  
    .coupon-modal-button {
      font-size: 1rem;
    }
  }
  
  @media (max-width: 480px) {
    .coupon-modal {
      padding: 1rem;
    }
  
    .coupon-modal-header {
      font-size: 1.5rem;
    }
  
    .coupon-modal-text {
      font-size: 0.9rem;
    }
  
    .coupon-modal-button {
      font-size: 0.95rem;
    }
  }
  