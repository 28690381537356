/* Global Styles for Blog */
html, body {
  overflow-x: hidden;
}

/* Text Selection Prevention */
.header-container,
.header-text,
.logo,
.logo-img,
.header-text-left,
.header-text-right {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
}

/* Header Container */
.header-container {
  display: flex;
  align-items: center;
  width: 100%;
  height: 60px;
  padding: 0 20px;
  background-color: rgba(255, 255, 255, 0.9);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  z-index: 1000;
  margin-bottom: 20px;
  justify-content: center;
}

/* Header Flexbox */
.header-flex {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  width: 100%;
}

/* Main Content Adjustment */
.main-content {
  padding-top: 80px;
}

/* Hamburger Menu Styling */
.hamburger-menu {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 24px;
  height: 18px;
  cursor: pointer;
  position: absolute;
  left: 20px;
  z-index: 1100;
  transition: all 0.3s ease;
  -webkit-tap-highlight-color: transparent;
}

.bar {
  width: 24px;
  height: 3px;
  background-color: #333;
  transition: all 0.3s ease;
}

/* Logo Styling */
.logo {
  display: flex;
  align-items: center;
  margin: 0 3px;
  -webkit-touch-callout: none;
  cursor: pointer;
}

.logo-img {
  height: 82px;
  width: auto;
  transition: height 0.3s ease;
  pointer-events: none;
  -webkit-user-drag: none;
  user-drag: none;
}

/* Cart Icon Styling */
.cart-icon {
  display: flex;
  align-items: center;
  position: absolute;
  right: 0;
  margin-right: 65px;
  z-index: 1100;
  -webkit-tap-highlight-color: transparent;
}

.cart-img {
  width: 48px;
  height: auto;
  cursor: pointer;
  transition: all 0.3s ease;
  max-width: 100%;
  -webkit-user-drag: none;
  user-drag: none;
}

.cart-img:hover {
  transform: scale(1.1);
}

/* Header Text Styling */
.header-text {
  font-size: 3rem;
  font-weight: bold;
  color: white;
  z-index: 1100;
  display: flex;
  align-items: center;
  text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000;
  letter-spacing: 15px;
  transition: all 0.3s ease;
  pointer-events: none;
  format-detection: none;
}

/* Text to the left of the logo */
.header-text-left {
  margin-right: 5px;
}

/* Text to the right of the logo */
.header-text-right {
  margin-left: 10px;
}

/* Enhanced Responsive Design */
@media (max-width: 1024px) {
  .header-text {
    font-size: 2.5rem;
    letter-spacing: 12px;
  }
  
  .logo-img {
    height: 75px;
  }

  .cart-img {
    width: 45px;
  }

  .cart-icon {
    margin-right: 55px;
  }
}

@media (max-width: 768px) {
  .header-container {
    height: 55px;
    padding: 0 15px;
  }

  .header-flex {
    flex-direction: row;
    align-items: center;
  }

  .header-text {
    font-size: 2rem;
    letter-spacing: 8px;
  }

  .header-text-left, .header-text-right {
    margin: 0 5px;
  }

  .logo-img {
    height: 65px;
  }

  .cart-icon {
    right: 15px;
    margin-right: 35px;
  }

  .cart-img {
    width: 42px;
  }

  .hamburger-menu {
    left: 15px;
    width: 20px;
    height: 15px;
  }

  .bar {
    width: 20px;
    height: 2.5px;
  }
}

@media (max-width: 600px) {
  .header-container {
    height: 50px;
    padding: 0 12px;
  }

  .logo-img {
    height: 58px;
  }

  .header-text {
    font-size: 1.6rem;
    letter-spacing: 6px;
  }

  .cart-icon {
    margin-right: 45px;
  }

  .cart-img {
    width: 38px;
  }

  .hamburger-menu {
    left: 12px;
    scale: 0.9;
  }
}

@media (max-width: 480px) {
  .header-container {
    padding: 0 10px;
  }

  .logo-img {
    height: 52px;
  }

  .header-text {
    font-size: 1.4rem;
    letter-spacing: 4px;
  }

  .header-text-left {
    margin-right: 4px;
  }

  .header-text-right {
    margin-left: 4px;
  }

  .cart-icon {
    margin-right: 40px;
  }
}

@media (max-width: 492px) {
  .cart-icon {
    margin-right: 25px;
    right: 10px;
  }


}

@media (max-width: 425px) {
  .header-text-left,
  .header-text-right {
    display: none;
  }

  .logo-img {
    height: 48px;
  }

  .cart-img {
    width: 36px;
  }

  .cart-icon {
    margin-right: 35px;
    right: 10px;
  }

  .hamburger-menu {
    left: 12px;
    width: 18px;
    height: 14px;
  }

  .bar {
    width: 18px;
    height: 2px;
  }
}

@media (max-width: 375px) {
  .header-text-left,
  .header-text-right {
    display: none;
  }

  .logo-img {
    height: 45px;
  }

  .cart-img {
    width: 34px;
  }

  .cart-icon {
    margin-right: 30px;
    right: 15px;
  }

  .hamburger-menu {
    left: 10px;
    width: 18px;
    height: 14px;
  }

  .bar {
    width: 18px;
    height: 2px;
  }
}

/* Transformations for Hamburger to "X" */
.hamburger-menu.open .bar1 {
  transform: rotate(45deg) translate(5px, 5px);
}

.hamburger-menu.open .bar2 {
  opacity: 0;
}

.hamburger-menu.open .bar3 {
  transform: rotate(-45deg) translate(5px, -5px);
}

/* Hover Effect for Bars */
.hamburger-menu:hover .bar {
  background-color: #555;
}

/* Dropdown Menu Styling */
.dropdown-menu {
  position: absolute;
  top: 60px;
  left: 20px;
  background-color: rgba(255, 255, 255, 0.95);
  border-radius: 8px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  display: none;
  flex-direction: column;
  z-index: 1090;
  animation: dropdownAppear 0.3s ease-in-out;
  width: 150px;
}

/* Enhanced dropdown menu for mobile */
@media (max-width: 768px) {
  .dropdown-menu {
    top: 55px;
    left: 10px;
    width: 180px;
  }

  .dropdown-link {
    padding: 14px 20px;
    font-size: 1.1rem;
  }
}

/* Show dropdown when visible class is added */
.dropdown-menu.visible {
  display: flex;
}

/* Dropdown Animation */
@keyframes dropdownAppear {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.dropdown-link {
  padding: 12px 20px;
  font-size: 1rem;
  color: #333;
  text-decoration: none;
  transition: background-color 0.3s, color 0.3s;
}

.dropdown-link:hover {
  background-color: #f0f0f0;
  color: #000;
}

/* Divider for admin section */
.dropdown-divider {
  height: 1px;
  background-color: #e2e8f0;
  margin: 8px 0;
}

/* Admin-specific link styling */
.admin-link {
  color: #8fc760;
  font-weight: 600;
}

.admin-link:hover {
  background-color: #f0f9ef;
}

/* Logout button styling */
.logout-button {
  width: 100%;
  text-align: left;
  background: none;
  border: none;
  padding: 12px 20px;
  font-size: 1rem;
  color: #ff3b30;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.logout-button:hover {
  background-color: #fff5f5;
}

/* Cart Item Count Badge */
.cart-item-count {
  position: absolute;
  top: -5px;
  right: -12px;
  background-color: #ff3b30;
  color: white;
  font-size: 0.75rem;
  font-weight: bold;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

/* Enhanced cart badge responsiveness */
@media (max-width: 768px) {
  .cart-item-count {
    top: -3px;
    right: -10px;
    width: 16px;
    height: 16px;
    font-size: 0.7rem;
  }
}