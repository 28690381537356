/* Home Container Styling */
body, html {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* Global disable for horizontal scrolling */
}

/* Home Container Styling */
.home-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  font-family: 'Helvetica Neue', sans-serif;
  background-color: #f9f9f9;
  color: #333;
  overflow-x: hidden; /* Disable horizontal scrolling */
}

/* Hero Section */
.hero-section {
  background-image: url('../imgs/hydroLid_GROUP_01.png');
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 60px; /* Increased margin for slightly more spacing */
}

/* Responsive Adjustments for Hero Section */
@media (max-width: 1024px) {
  .hero-section {
    height: 70vh; /* Reduced height for tablets and smaller desktops */
  }
}

@media (max-width: 768px) {
  .hero-section {
    height: 60vh; /* Further reduced height for tablets */
  }
}

@media (max-width: 480px) {
  .hero-section {
    height: 50vh; /* Minimal height for mobile devices */
  }
}


/* Hero Content Styling */
.hero-content {
  background: rgba(255, 255, 255, 0.6); /* Increased transparency */
  padding: 20px;
  border-radius: 12px;
  text-align: center;
  backdrop-filter: blur(8px); /* Adds subtle blur effect */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow */
  max-width: 90%;
  margin: auto;
  transition: background 0.3s ease-in-out;
}

.hero-content:hover {
  background: rgba(255, 255, 255, 0.75); /* Slightly darker on hover */
}

.hero-title {
  font-size: 3rem;
  font-weight: bold;
  color: #8fc760;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.7); /* Softer, diffused shadow */
  margin-bottom: 15px;
  letter-spacing: 2px; /* Adds spacing between characters */
  transition: color 0.3s;
}

.hero-title:hover {
  color: #f5f5f5; /* Lighter shade on hover */
}

/* Hero Description with Subtler Styling */
.hero-description {
  font-size: 1.2rem;
  font-weight: normal; /* Regular font weight for subtlety */
  color: rgba(0, 0, 0, 0.8); /* Slight transparency for text */
  margin-bottom: 20px;
  line-height: 1.4; /* Better line spacing */
  transition: color 0.3s;
}

.hero-description:hover {
  color: rgba(0, 0, 0, 0.9); /* Slightly darker on hover */
}

/* Hero Button with Minimalist Design */
.hero-button {
  padding: 12px 30px;
  font-size: 1.1rem;
  background-color: rgba(51, 51, 51, 0.8); /* Transparent dark color */
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.hero-button:hover {
  background-color: rgba(85, 85, 85, 0.9); /* Darker on hover */
  transform: scale(1.05); /* Slight scale effect */
}


/* Rounded Corners for Hero Image */
.hero-section {
  border-radius: 15px; /* Add rounded corners to the hero image */
  overflow: hidden; /* Ensure the rounded corners are visible */
}

/* Features Section */
.features-section {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 100%;
  padding: 40px 0;
  gap: 20px; /* Space between feature cards */
}

.feature {
  width: 30%;
  text-align: center;
  padding: 25px;
  background: linear-gradient(145deg, #f0f0f0, #e0e0e0); /* Subtle gradient background */
  border-radius: 12px;
  border: 1px solid rgba(0, 0, 0, 0.05); /* Soft border for a more defined edge */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1); /* Soft, layered shadow */
  transition: transform 0.3s, box-shadow 0.3s;
}

.feature:hover {
  transform: scale(1.05); /* Slightly enlarge on hover */
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.15), 0 4px 8px rgba(0, 0, 0, 0.1); /* Deeper shadow on hover */
  background: linear-gradient(145deg, #e8e8e8, #d8d8d8); /* Lighten gradient on hover */
}


/* Feature Title in Bubble Letter Style with Gradient */
.feature h2 {
  font-size: 1.6rem;
  font-weight: bold;
  color: #333;
  background: linear-gradient(to right, #8fc760, #4a90e2); /* Gradient for bubble effect */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 15px;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3); /* Adds a soft shadow for a bubble effect */
  font-family: 'BubbleLetterFont', sans-serif; /* Ensure your bubble font is applied */
  letter-spacing: 4px; /* Character spacing for styling */
  transition: transform 0.3s ease-in-out;
}

.feature h2:hover {
  transform: scale(1.1); /* Slightly scale text on hover */
  text-shadow: 3px 3px 7px rgba(0, 0, 0, 0.4); /* Increase shadow on hover for depth */
}

/* Feature Paragraphs with Subtle Transition and Enhanced Spacing */
.feature p {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  padding: 0 15px; /* Adds space around the text for better readability */
  transition: color 0.3s ease;
}

.feature p:hover {
  color: #333; /* Darken text on hover */
}


/* Responsive Design for Features Section */
@media (max-width: 768px) {
  body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Global disable for horizontal scrolling */
  }
  
  .features-section {
    flex-direction: column; /* Stack features vertically */
    padding: 20px 10px;
    margin: auto;
  }

  .feature {
    width: 90%;
    margin-bottom: 20px; /* Add space between stacked features */
  }

  .feature h2 {
    font-size: 1.4rem; /* Slightly smaller for readability on smaller screens */
  }

  .feature p {
    font-size: 0.95rem;
  }
}

@media (max-width: 480px) {
  .features-section {
    padding: 20px 5px; /* Reduce padding for extra-small screens */
  }

  .feature {
    width: 80%;
    margin-bottom: 20px; /* Add space between stacked features */
  }

  .feature h2 {
    font-size: 1.3rem;
  }

  .feature p {
    font-size: 0.9rem;
  }
}
/* Product Showcase Section */
.product-showcase {
  margin-top: 60px;
  padding: 40px 20px;
  background-color: #f5f5f5;
  text-align: center;
  border-radius: 12px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
}

.showcase-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 30px;
  color: #333;
}

.product-grid {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  flex-wrap: wrap;
}

.product-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 280px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.product-card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.product-image {
  cursor: pointer;
  width: 100%;
  height: 180px;
  object-fit: cover;
  border-radius: 8px;/* Home Container Styling */
  body, html {
    margin: 0;
    padding: 0;
    overflow-x: hidden; /* Global disable for horizontal scrolling */
  }
  
  /* Home Container Styling */
  .home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    font-family: 'Helvetica Neue', sans-serif;
    background-color: #f9f9f9;
    color: #333;
    overflow-x: hidden; /* Disable horizontal scrolling */
  }
  
  /* Hero Section */
  .hero-section {
    background-image: url('../imgs/hero-image.jpg');
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-top: 60px; /* Increased margin for slightly more spacing */
  }
  
  .hero-content {
    background: rgba(255, 255, 255, 0.8);
    padding: 30px;
    border-radius: 10px;
    text-align: center;
  }
  
  /* Hero Title with Dark Outline and White Fill */
  .hero-title {
    font-size: 3rem;
    font-weight: bold;
    color: white; /* White fill for the text */
    text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000; /* Dark outline */
    margin-bottom: 20px;
    letter-spacing: 2px; /* Adds spacing between characters */
  }
  
  /* Hero Description with Bold Black Color */
  .hero-description {
    font-size: 1.2rem;
    font-weight: bold;
    color: #000; /* Hard bold black color */
    margin-bottom: 20px;
  }
  
  .hero-button {
    padding: 10px 20px;
    font-size: 1.1rem;
    background-color: #333;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .hero-button:hover {
    background-color: #555;
  }
  
  /* Rounded Corners for Hero Image */
  .hero-section {
    border-radius: 15px; /* Add rounded corners to the hero image */
    overflow: hidden; /* Ensure the rounded corners are visible */
  }
  
  /* Features Section */
  .features-section {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
    padding: 40px 0;
  }
  
  .feature {
    width: 30%;
    text-align: center;
  }
  
  /* Feature Title with Dark Outline, White Fill, and Increased Letter Spacing */
  .feature h2 {
    font-size: 1.5rem;
    font-weight: bold;
    color: white; /* White fill for the text */
    text-shadow: -2px -2px 0 #000, 2px -2px 0 #000, -2px 2px 0 #000, 2px 2px 0 #000; /* Dark outline */
    margin-bottom: 10px;
    letter-spacing: 4px; /* Increased character spacing */
  }
  
  .feature p {
    font-size: 1rem;
    color: #666;
  }
  
  /* Product Showcase Section */
  .product-showcase {
    margin-top: 60px;
    padding: 40px 20px;
    background-color: #f5f5f5;
    text-align: center;
    border-radius: 12px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.1);
  }
  
  .showcase-title {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 30px;
    color: #333;
  }
  
  .product-grid {
    display: flex;
    justify-content: space-around;
    gap: 20px;
    flex-wrap: wrap;
  }
  
  .product-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 280px;
    text-align: center;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .product-card:hover {
    transform: scale(1.05);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
  }
  
  .product-image {
    width: 100%;
    height: 180px;
    object-fit: cover;
    border-radius: 8px;
    margin-bottom: 15px;
  }
  
  .product-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 10px;
    color: #333;
  }
  
  .product-description {
    font-size: 1rem;
    color: #666;
    margin-bottom: 10px;
  }
  
  .product-price {
    font-size: 1.2rem;
    font-weight: bold;
    color: #008000;
    margin-bottom: 15px;
  }
  
  .product-button {
    padding: 10px 25px;
    font-size: 1.1rem;
    background-color: #008000;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .product-button:hover {
    background-color: #005500;
    transform: scale(1.05);
  }
  
  /* Responsive Design for Product Showcase */
  @media (max-width: 768px) {
    .product-grid {
      flex-direction: column;
      align-items: center;
    }
  
    .product-card {
      width: 90%;
      margin-bottom: 20px;
    }
  
    .showcase-title {
      font-size: 2rem;
    }
  }
  
  /* About Us Section */
  .about-section {
    margin-top: 60px;
    padding: 40px 20px;
    background-color: #f5f5f5;
    text-align: center;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .about-title {
    font-size: 2rem;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* About Us Content Styling */
  .about-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 40px;
    max-width: 1000px;
    margin: 0 auto;
  }
  
  .about-image {
    width: 50%;
    height: auto;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .about-text {
    width: 50%;
  }
  
  .about-description, .about-mission {
    font-size: 1.1rem;
    color: #666;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  /* Responsive Design for About Section */
  @media (max-width: 768px) {
    .about-content {
      flex-direction: column;
    }
  
    .about-image {
      width: 80%;
    }
  
    .about-text {
      width: 100%;
    }
  
    .about-title {
      font-size: 1.5rem;
    }
  
    .about-description, .about-mission {
      font-size: 1rem;
    }
  }
  
}

.product-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.product-description {
  font-size: 1rem;
  color: #666;
  margin-bottom: 10px;
}

.product-price {
  font-size: 1.2rem;
  font-weight: bold;
  color: #008000;
  margin-bottom: 15px;
}

.product-button {
  padding: 10px 25px;
  font-size: 1.1rem;
  background-color: #008000;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.product-button:hover {
  background-color: #005500;
  transform: scale(1.05);
}

/* Responsive Design for Product Showcase */
@media (max-width: 768px) {
  .product-grid {
    flex-direction: column;
    align-items: center;
  }

  .product-card {
    width: 90%;
    margin-bottom: 20px;
  }

  .showcase-title {
    font-size: 2rem;
  }
}

/* About Us Section */
.about-section {
  margin-top: 60px;
  padding: 40px 20px;
  background-color: #f5f5f5;
  text-align: center;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.about-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
}

/* About Us Content Styling */
.about-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 40px;
  max-width: 1000px;
  margin: 0 auto;
}

.about-image {
  width: 50%;
  height: auto;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.about-text {
  width: 50%;
}

.about-description, .about-mission {
  font-size: 1.1rem;
  color: #666;
  line-height: 1.6;
  margin-bottom: 20px;
}

/* Responsive Design for About Section */
@media (max-width: 768px) {
  .about-content {
    flex-direction: column;
  }

  .about-image {
    width: 80%;
  }

  .about-text {
    width: 100%;
  }

  .about-title {
    font-size: 1.5rem;
  }

  .about-description, .about-mission {
    font-size: 1rem;
  }
}

/* Reviews Section Styling */
.reviews-section {
  margin-bottom: 80px;
  margin-top: 60px;
  padding: 40px 20px;
  background-color: #f1f1f1;
  text-align: center;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.reviews-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 30px;
  color: #333;
}

.reviews-grid {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  flex-wrap: wrap;
}

.review-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  padding: 20px;
  width: 280px;
  text-align: center;
  transition: transform 0.3s, box-shadow 0.3s;
}

.review-card:hover {
  transform: scale(1.05);
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.review-image {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50%;
  margin-bottom: 15px;
}

.review-content {
  margin-top: 10px;
}

.review-title {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

.review-message {
  font-size: 1rem;
  color: #666;
  font-style: italic;
  margin-bottom: 10px;
}

.review-author {
  font-size: 1rem;
  color: #555;
  font-weight: bold;
}


/* Contact Section */
.contact-section {
  margin-top: 80px; /* Extra space above the section */
  padding: 60px 20px;
  background-color: #f9f9f9;
  text-align: center;
  border-radius: 12px;
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
  max-width: 100%;
  margin: 0 auto;
}

.contact-title {
  font-size: 2.5rem;
  font-weight: bold;
  margin-bottom: 10px;
  color: #333;
}

.contact-subtitle {
  font-size: 1.2rem;
  margin-bottom: 30px;
  color: #666;
}

.contact-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px; /* Ensures form stays centered */
  margin: 0 auto; /* Centers the form container */
}

.form-row {
  display: flex;
  gap: 20px;
  width: 100%;
}

.contact-input, .contact-select, .contact-textarea {
  width: 100%;
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 8px;
  font-size: 1.1rem;
  transition: border-color 0.3s;
  box-sizing: border-box; /* Ensures padding is included in total width */
}

.contact-input:focus, .contact-select:focus, .contact-textarea:focus {
  border-color: #008000;
  outline: none;
}

.contact-textarea {
  height: 150px;
  resize: vertical;
}

/* Checkbox Styling */
.newsletter-checkbox {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.newsletter-checkbox input {
  margin-right: 8px;
}

.newsletter-checkbox label {
  font-size: 1rem;
}

/* Button Styling */
.contact-button {
  margin-top: 20px;
  padding: 12px 30px; /* Same padding as hero button */
  font-size: 1.2rem;
  background-color: rgba(51, 51, 51, 0.8); /* Transparent dark color */
  color: #fff;
  border: 1px solid rgba(255, 255, 255, 0.8);
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.contact-button:hover {
  background-color: rgba(85, 85, 85, 0.9); /* Darker on hover */
  transform: scale(1.05); /* Slight scale effect */
}

/* Responsive Design for Contact Form */
@media (max-width: 768px) {
  .form-row {
    flex-direction: column; /* Stack input fields vertically */
    gap: 10px; /* Adjust gap for better alignment */
  }

  .contact-title {
    font-size: 2rem;
  }

  .contact-subtitle {
    font-size: 1rem;
  }

  .contact-form {
    max-width: 90%; /* Makes the form more centered */
    padding: 0 10px;
  }

  .contact-button {
    font-size: 1.1rem;
  }
}

@media (max-width: 480px) {
  .contact-section {
    padding: 30px 10px; /* Adjust padding for mobile */
  }

  .contact-form {
    max-width: 100%; /* Ensures form takes full width */
    padding: 0 5px;
  }

  .form-row {
    gap: 0; /* Remove gap to prevent overflow on very small screens */
  }

  .contact-title {
    font-size: 1.8rem;
  }

  .contact-subtitle {
    font-size: 0.95rem;
  }

  .contact-button {
    font-size: 1rem;
    padding: 10px 20px; /* Adjust button size for smaller screens */
  }
}

