/* Shop Container */
.shop-container {
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
}

.shop-title {
  font-size: 2.5rem;
  font-weight: bold;
  text-align: center;
  color: #333;
  margin-bottom: 40px;
}

/* Category Selector Wrapper */
.category-selector-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  background: linear-gradient(145deg, #e0e0e0, #f0f0f0); /* Subtle gradient for Apple-like feel */
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1); /* Soft shadow for depth */
  margin: 20px 0;
  max-width: 100%;
  transition: all 0.3s ease;
}

.category-selector-wrapper:hover {
  background: linear-gradient(145deg, #d8d8d8, #f8f8f8); /* Slightly lightened gradient on hover */
  box-shadow: 0 6px 16px rgba(0, 0, 0, 0.2);
}

.category-selector {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
}

/* Category Selector Button */
.category-selector button {
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: bold;
  color: #ffffff;
  background: linear-gradient(145deg, #8fc760, #75b04a); /* Gradient button background */
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s, box-shadow 0.3s;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  min-width: 120px; /* Ensures consistent button width */
}

.category-selector button:hover {
  background: linear-gradient(145deg, #75b04a, #8fc760); /* Invert gradient on hover */
  transform: scale(1.05);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.category-selector button:active {
  transform: scale(0.95);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

/* Responsive Styles for Category Selector */
@media (max-width: 768px) {
  .category-selector-wrapper {
    padding: 10px;
  }

  .category-selector {
    gap: 8px;
  }

  .category-selector button {
    padding: 8px 16px;
    font-size: 0.9rem;
    min-width: 100px;
  }
}

@media (max-width: 480px) {
  .category-selector-wrapper {
    padding: 8px;
  }

  .category-selector {
    gap: 5px;
  }

  .category-selector button {
    padding: 6px 12px;
    font-size: 0.85rem;
    min-width: 90px;
  }
}

@media (max-width: 375px) {
  .category-selector button {
    padding: 5px 10px;
    font-size: 0.8rem;
    min-width: 80px;
  }
}


/* Section Titles */
.section-title {
  font-size: 2rem;
  font-weight: bold;
  color: #8fc760;
  margin-bottom: 20px;
  text-align: center;
  position: relative;
}

.section-title::after {
  content: '';
  width: 80px;
  height: 4px;
  background-color: #8fc760;
  display: block;
  margin: 10px auto 0;
  border-radius: 2px;
}

/* Item Grid */
.item-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 30px;
  justify-content: center;
}

/* 8oz Product Row */
.row-of-four {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 20px;
  max-width: 100%;
}

@media (max-width: 1200px) {
  .row-of-four {
    flex-wrap: wrap;
    justify-content: center;
  }
}

/* 16oz Product Row */
.row-of-four-16oz {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
  gap: 20px;
  max-width: 100%;
}

@media (max-width: 1200px) {
  .row-of-four-16oz {
    flex-wrap: wrap;
    justify-content: center;
  }
}

/* Standalone Grid - Desktop Row Layout */
.standalone-grid {
  display: flex;
  flex-wrap: nowrap;
  gap: 20px;
  justify-content: center;
}

/* Two-column layout for medium screens */
@media (max-width: 1200px) {
  .standalone-grid {
    flex-wrap: wrap;
    justify-content: center;
  }
}

/* Single column layout on smaller screens */
@media (max-width: 768px) {
  .standalone-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 20px;
    justify-content: center;
  }
}

/* Item Card with Landscape Orientation */
.item-card.landscape {
  display: flex;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
  max-width: 520px;
  width: 100%;
  transition: transform 0.3s, box-shadow 0.3s;
  align-items: center;
  gap: 20px;
}

.item-card.landscape:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 20px rgba(0, 0, 0, 0.2);
}

/* Item Image Wrapper for Landscape Card */
.item-image-wrapper {
  flex-shrink: 0;
  width: 280px;
  height: 200px;
}

.item-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px;
  transition: transform 0.3s;
}

.item-image:hover {
  transform: scale(1.05);
  cursor: pointer;
}

/* Item Content in Landscape Card */
.item-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding-left: 10px;
}

/* Item Title */
.item-title {
  font-size: 1.3rem;
  font-weight: bold;
  color: #333;
  margin: 0;
  text-align: left;
}

/* Item Price */
.item-price {
  font-size: 1.2rem;
  font-weight: bold;
  color: #333;
  margin-top: 10px;
  text-align: left;
}

/* Item Original Price */
.item-original-price {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 5px;
}

.crossed-price {
  color: red;
  font-size: 1rem;
  text-decoration: line-through;
}

.item-discount {
  color: red;
  font-size: 1rem;
  font-weight: bold;
}

/* Quantity and Add-to-Cart in Landscape Card */
.quantity-add {
  display: flex;
  align-items: center;
  gap: 10px;
  margin-top: 10px;
}

/* Quantity Selector */
.quantity-selector {
  display: flex;
  align-items: center;
  gap: 5px;
}

.quantity-label {
  font-size: 0.9rem;
  color: #333;
  font-weight: bold;
}

/* Apple-style Input */
.apple-style-input {
  width: 60px;
  padding: 6px;
  font-size: 1rem;
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s, box-shadow 0.3s;
}

.apple-style-input:focus {
  border-color: #007aff;
  outline: none;
  box-shadow: 0 0 8px rgba(0, 122, 255, 0.5);
}

.apple-style-input:hover {
  border-color: #007aff;
}

/* Enhanced Add to Cart Button */
.add-to-cart-btn {
  padding: 12px 24px; /* Larger padding */
  font-size: 1rem; /* Slightly larger font */
  font-weight: bold;
  color: #fff;
  background-color: #8fc760;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2); /* Add shadow for elegance */
}

.add-to-cart-btn:hover {
  background-color: #75b04a;
  transform: scale(1.05); /* Slight scale-up on hover */
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.3); /* Deepened shadow on hover */
}

.add-to-cart-btn:active {
  transform: scale(0.98);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.15); /* Soften shadow on click */
}

/* Standalone Specific Add-to-Cart Button on Larger Screens */
.standalone-grid .add-to-cart-btn {
  padding: 10px 20px;
  font-size: 0.95rem;
}


@media (max-width: 768px) {
  .item-card.landscape {
    margin: auto;
    flex-direction: column;
    align-items: center;
    max-width: 85%;
  }

  .item-image-wrapper {
    width: 100%;
    height: 400px;
  }

  .item-content {
    text-align: center;
  }

  .add-to-cart-btn {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .item-card {
    width: 100%;
  }

  .item-image-wrapper {
    width: 100%;
    height: 220px;
  }
}

@media (max-width: 320px) {
  .item-card {
    width: 100%;
  }

  .item-image-wrapper {
    width: 100%;
    height: 180px;
  }
}

/* Custom Notification Popup */
.custom-notification {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #007aff;
  color: white;
  padding: 15px 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.2);
  font-size: 1rem;
  font-weight: bold;
  z-index: 1000;
  animation: fadeInOut 3s ease-in-out;
}

@keyframes fadeInOut {
  0%, 100% { opacity: 0; }
  10%, 90% { opacity: 1; }
}

/* Adjusting product layout for screens between 1440px and 2560px */
@media (min-width: 1440px) and (max-width: 2559px) {
  /* General grid adjustments for two items per row */
  .item-grid, .row-of-four, .row-of-four-16oz, .standalone-grid {
    display: grid;
    grid-template-columns: 1fr 1fr; /* Two columns layout */
    gap: 30px; /* Consistent spacing between items */
    justify-content: center;
  }

  /* Specific adjustments if needed for standalone grid */
  .standalone-grid {
    grid-template-columns: repeat(2, 1fr);
  }
}
