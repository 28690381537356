/* Global Styles */
html, body {
  overflow-x: hidden; /* Hide horizontal overflow */
}

/* Footer Container */
.footer-container {
  width: 100%;
  padding: 60px 20px;
  background-color: #1c1c1e; /* Darker tone for a modern look */
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow-x: hidden;
  box-shadow: 0 -2px 10px rgba(0, 0, 0, 0.2);
}

/* Footer Content */
.footer-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  gap: 30px;
  max-width: 1200px;
  width: 100%;
}

/* Footer Logo */
.footer-logo {
  margin-bottom: 20px;
  cursor: pointer;
}

.footer-logo-img {
  width: 150px;
  transition: transform 0.3s;
}

.footer-logo-img:hover {
  transform: scale(1.05);
}

/* Footer Links */
.footer-links {
  display: flex;
  justify-content: center; /* Center the links */
  gap: 40px;
}

.footer-link {
  color: #fff;
  font-size: 1rem;
  font-weight: 500;
  text-decoration: none;
  padding: 5px 0;
  border-bottom: 2px solid transparent;
  transition: color 0.3s, border-color 0.3s;
  cursor: pointer;
}

.footer-link:hover {
  color: #8fc760; /* Light green hover effect */
  border-color: #8fc760;
}

/* Social Media Links */
.footer-social {
  display: flex;
  justify-content: center; /* Center the social icons */
  gap: 20px;
  margin-top: 15px;
}

.social-link {
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.3s;
}

.social-icon {
  width: 40px;
  height: 40px;
  transition: transform 0.3s, box-shadow 0.3s;
  border-radius: 50%;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.social-icon:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(140, 199, 96, 0.5); /* Soft green glow on hover */
}

/* Subscription Section */
.footer-subscription {
  text-align: center;
}

.subscription-title {
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 5px;
  color: #fff;
}

.subscription-text {
  font-size: 0.9rem;
  margin-bottom: 10px;
  color: #ccc;
}

/* Subscription Form */
.subscription-form {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  max-width: 100%;
}

.subscription-input {
  padding: 10px;
  font-size: 1rem;
  border: 2px solid #333;
  border-radius: 8px 0 0 8px;
  outline: none;
  transition: border-color 0.3s;
  flex: 1;
  min-width: 200px;
  box-sizing: border-box;
}

.subscription-input:focus {
  border-color: #8fc760;
}

.subscription-btn {
  padding: 10px 20px;
  font-size: 1rem;
  background-color: #8fc760;
  color: #fff;
  border: none;
  border-radius: 0 8px 8px 0;
  cursor: pointer;
  transition: background-color 0.3s;
  box-sizing: border-box;
}

.subscription-btn:hover {
  background-color: #75b04a;
}

/* Copyright */
.footer-copyright {
  font-size: 0.8rem;
  color: #aaa;
  margin-top: 20px;
}

/* Responsive Design for Footer */
@media (max-width: 768px) {
  .footer-content {
    gap: 15px;
  }

  .footer-links {
    flex-direction: column;
    gap: 10px;
    align-items: center; /* Center links on smaller screens */
  }

  .footer-social {
    margin-top: 10px;
    justify-content: center; /* Center social icons */
  }

  .subscription-form {
    flex-direction: column;
    align-items: stretch;
  }

  .subscription-input,
  .subscription-btn {
    border-radius: 8px;
    width: 100%;
    margin: 5px 0;
  }
}

/* Additional Responsive Design for 375px and below */
@media (max-width: 375px) {
  .footer-content {
    padding: 20px;
    gap: 10px;
  }

  .footer-links {
    gap: 5px;
  }

  .subscription-input,
  .subscription-btn {
    width: 100%; /* Ensures input and button take full width on small screens */
    border-radius: 8px;
  }

  .subscription-input {
    min-width: 0; /* Removes minimum width for very small screens */
  }

  .subscription-btn {
    margin-top: 5px; /* Adds space between input and button when stacked */
  }
}
