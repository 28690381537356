.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: #f9f9f9;
  border-radius: 12px;
  padding: 16px; /* Reduced padding */
  width: 90%;
  max-width: 500px;
  position: relative;
  text-align: center;
  overflow-y: auto;
  scrollbar-width: none; /* For Firefox */
}

.modal-content::-webkit-scrollbar {
  display: none; /* For Chrome, Safari, and Opera */
}

.modal-close {
  position: absolute;
  top: 4px; /* Slightly adjusted */
  right: 6px; /* Slightly adjusted */
  font-size: 1.4rem; /* Reduced size */
  background: none;
  border: none;
  cursor: pointer;
  color: #333;
  transition: color 0.3s ease, transform 0.3s ease;
}

.modal-close:hover {
  color: #8fc760;
  transform: scale(1.2);
}

.modal-image {
  width: 75%; /* Reduced width */
  border-radius: 8px;
  margin-top: -10px;
  margin-bottom: 16px; /* Reduced margin */
}

.modal-details {
  padding: 8px 0; /* Reduced padding */
}

.modal-title {
  font-size: 1.4rem; /* Slightly smaller */
  font-weight: bold;
  color: #333;
  margin-bottom: 8px; /* Reduced margin */
}

.modal-description {
  font-size: 0.95rem; /* Slightly smaller */
  color: #666;
  margin: 8px 0; /* Reduced margin */
  line-height: 1.4; /* Reduced line spacing */
}

.modal-price-section {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px; /* Reduced gap */
  margin-top: 12px; /* Reduced margin */
}

.modal-price-message {
  font-size: 0.9rem; /* Reduced size */
  color: #555;
  font-style: italic;
  margin-bottom: 4px; /* Reduced margin */
  text-align: center;
}

.modal-original-price {
  font-size: 0.95rem; /* Reduced size */
  color: #b12704;
  text-decoration: line-through;
}

.modal-discount {
  color: #8fc760;
  font-size: 0.95rem; /* Reduced size */
  font-weight: bold;
}

.modal-price {
  font-size: 1.4rem; /* Reduced size */
  font-weight: bold;
  color: #333;
  margin-top: 8px; /* Reduced margin */
}

.quantity-add {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 12px; /* Reduced gap */
  margin-top: 16px; /* Reduced margin */
}

.quantity-selector {
  display: flex;
  align-items: center;
}

.quantity-label {
  font-size: 0.85rem; /* Slightly smaller */
  font-weight: bold;
  margin-right: 4px; /* Reduced margin */
}

.quantity-input.apple-style-input {
  width: 55px; /* Reduced width */
  padding: 4px; /* Reduced padding */
  font-size: 0.95rem; /* Slightly smaller */
  text-align: center;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1); /* Slightly reduced shadow */
  transition: border-color 0.3s, box-shadow 0.3s;
}

.add-to-cart-btn {
  padding: 8px 16px; /* Reduced padding */
  font-size: 0.95rem; /* Slightly smaller */
  font-weight: bold;
  color: #fff;
  background-color: #8fc760;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.add-to-cart-btn:hover {
  background-color: #75b04a;
}

/* Carousel Container */
.carousel-container {
  margin-top: 16px; /* Reduced margin */
  text-align: center;
}

.carousel-title {
  font-size: 1.1rem; /* Slightly smaller */
  font-weight: bold;
  color: #333;
  margin-bottom: 8px; /* Reduced margin */
}

/* Carousel Styling */
.carousel {
  display: flex;
  overflow-x: auto;
  gap: 10px; /* Reduced gap */
  padding: 8px 0; /* Reduced padding */
}

.carousel::-webkit-scrollbar {
  display: none;
}

.carousel-item {
  background-color: #f9f9f9;
  border-radius: 6px; /* Slightly smaller */
  padding: 8px; /* Reduced padding */
  min-width: 110px; /* Slightly smaller */
  max-width: 110px; /* Slightly smaller */
  text-align: center;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1); /* Slightly reduced shadow */
}

.carousel-item-image {
  width: 100%;
  height: 90px; /* Reduced height */
  object-fit: cover;
  border-radius: 6px; /* Slightly smaller */
  margin-bottom: 6px; /* Reduced margin */
}

.carousel-item-title {
  font-size: 0.85rem; /* Slightly smaller */
  font-weight: bold;
  color: #333;
  margin: 4px 0; /* Reduced margin */
}

.carousel-item-price {
  font-size: 0.8rem; /* Slightly smaller */
  color: #666;
}

.carousel-add-btn {
  padding: 5px 10px; /* Reduced padding */
  font-size: 0.75rem; /* Slightly smaller */
  font-weight: bold;
  color: #fff;
  background-color: #8fc760;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.carousel-add-btn:hover {
  background-color: #75b04a;
}

/* Color Selector Styling */
.color-selector {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 12px 0; /* Reduced margin */
  margin-top: -10px;
  margin-bottom: -25px;
}

.color-options {
  display: flex;
  gap: 8px; /* Reduced gap */
}

.color-option {
  width: 22px; /* Slightly smaller */
  height: 22px; /* Slightly smaller */
  border-radius: 50%;
  border: 2px solid transparent;
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
}

.color-option:hover {
  transform: scale(1.05); /* Reduced scale */
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.2); /* Slightly reduced shadow */
}

.color-option.active {
  border-color: #8fc760;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3); /* Slightly reduced shadow */
}
