.notification {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    padding: 15px 25px;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    opacity: 0;
    transform: translateY(-20px);
    transition: opacity 0.3s, transform 0.3s;
    z-index: 1500;
    font-family: 'Helvetica Neue', sans-serif;
  }
  
  .notification.show {
    opacity: 1;
    transform: translateY(0);
  }
  
  .notification-message {
    font-size: 1rem;
    margin: 0;
  }
  