/* AdminMellowAnalytics.css */
.analytics-wrapper {
    padding: 2rem;
    background-color: #f9fafb;
    border-radius: 12px;
    margin-bottom: 1rem;
  }
  
  .analytics-header {
    margin-bottom: 2.5rem;
    background: white;
    padding: 1.5rem;
    border-radius: 12px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  }
  
  .header-controls {
    display: flex;
    gap: 1.25rem;
    margin-bottom: 1.5rem;
  }
  
  .header-controls select {
    min-width: 160px;
    padding: 0.75rem 1rem;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    background-color: white;
    color: #374151;
    font-size: 0.925rem;
    transition: all 0.2s ease;
    cursor: pointer;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.05);
  }
  
  .header-controls select:hover {
    border-color: #8fc760;
    box-shadow: 0 2px 4px rgba(143, 199, 96, 0.15);
  }
  
  .analytics-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(260px, 1fr));
    gap: 1.5rem;
    margin-bottom: 2.5rem;
  }
  
  .analytics-card {
    background: white;
    border-radius: 12px;
    padding: 1.75rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
    border: 1px solid #e5e7eb;
  }
  
  .analytics-card:hover {
    transform: translateY(-4px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-color: #8fc760;
  }
  
  .card-header {
    display: flex;
    align-items: center;
    gap: 1rem;
    margin-bottom: 1.25rem;
  }
  
  .card-icon {
    color: #8fc760;
    background-color: rgba(143, 199, 96, 0.15);
    padding: 0.75rem;
    border-radius: 10px;
    transition: all 0.3s ease;
  }
  
  .analytics-card:hover .card-icon {
    transform: scale(1.1);
    background-color: rgba(143, 199, 96, 0.25);
  }
  
  .card-header h3 {
    font-size: 1rem;
    font-weight: 600;
    color: #4b5563;
    margin: 0;
  }
  
  .card-value {
    font-size: 1.75rem;
    font-weight: 700;
    color: #111827;
    margin: 0;
    line-height: 1.2;
  }
  
  .card-trend {
    font-size: 0.925rem;
    font-weight: 500;
    margin: 0.5rem 0 0 0;
    display: flex;
    align-items: center;
    gap: 0.25rem;
  }
  
  .card-trend.positive {
    color: #059669;
  }
  
  .card-trend.negative {
    color: #dc2626;
  }
  
  .chart-container {
    background: white;
    border-radius: 12px;
    padding: 2rem;
    margin-bottom: 2.5rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    border: 1px solid #e5e7eb;
  }
  
  .chart-container h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #374151;
    margin-bottom: 2rem;
  }
  
  .analytics-sections {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(320px, 1fr));
    gap: 2rem;
    margin-bottom: 2rem;
  }
  
  .section {
    background: white;
    border-radius: 12px;
    padding: 1.75rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
    border: 1px solid #e5e7eb;
    transition: all 0.3s ease;
  }
  
  .section:hover {
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    border-color: #8fc760;
  }
  
  .section h3 {
    font-size: 1.25rem;
    font-weight: 600;
    color: #374151;
    margin-bottom: 2rem;
  }
  
  /* User Actions Styling */
  .action-bars {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
  }
  
  .action-bar {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .action-label {
    font-size: 1rem;
    font-weight: 500;
    color: #4b5563;
  }
  
  .action-track {
    background: #f3f4f6;
    height: 10px;
    border-radius: 5px;
    position: relative;
    overflow: visible;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05);
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .action-track:hover {
    background: #ebedf0;
  }
  
  .action-progress {
    background: linear-gradient(90deg, #8fc760 0%, #75b04a 100%);
    height: 100%;
    border-radius: 5px;
    transition: all 0.4s ease;
  }
  
  .action-track:hover .action-progress {
    background: linear-gradient(90deg, #75b04a 0%, #8fc760 100%);
    filter: brightness(1.1);
  }
  
  .action-value {
    position: absolute;
    right: 0;
    top: -1.75rem;
    font-size: 0.875rem;
    font-weight: 600;
    color: #4b5563;
  }
  
  /* Tooltip Styling */
  .action-tooltip-wrapper {
    position: absolute;
    top: -10px;
    left: 50%;
    transform: translate(-50%, -100%);
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease;
    z-index: 100;
    pointer-events: none;
  }
  
  .action-track:hover .action-tooltip-wrapper {
    visibility: visible;
    opacity: 1;
    top: -15px;
  }
  
  .action-tooltip {
    background: white;
    border-radius: 8px;
    padding: 1rem;
    min-width: 250px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
    border: 1px solid #e5e7eb;
    position: relative;
    text-align: left;
  }
  
  .action-tooltip::after {
    content: '';
    position: absolute;
    bottom: -5px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
    width: 10px;
    height: 10px;
    background: white;
    border-right: 1px solid #e5e7eb;
    border-bottom: 1px solid #e5e7eb;
  }
  
  .tooltip-grid {
    display: grid;
    gap: 0.75rem;
  }
  
  .tooltip-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.25rem 0;
    border-bottom: 1px dashed #e5e7eb;
  }
  
  .tooltip-item:last-child {
    border-bottom: none;
  }
  
  .tooltip-label {
    color: #6b7280;
    font-weight: 500;
    font-size: 0.875rem;
  }
  
  .tooltip-value {
    color: #111827;
    font-weight: 600;
    font-size: 0.875rem;
  }
  
  /* Device Breakdown Styling */
  .device-stats {
    display: flex;
    flex-direction: column;
    gap: 1.75rem;
  }
  
  .device-stat {
    display: flex;
    flex-direction: column;
    gap: 0.75rem;
  }
  
  .device-info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 0.25rem;
  }
  
  .device-name {
    font-size: 1rem;
    font-weight: 500;
    color: #4b5563;
  }
  
  .device-percentage {
    font-size: 1rem;
    font-weight: 600;
    color: #111827;
  }
  
  .device-bar {
    background: #f3f4f6;
    height: 8px;
    border-radius: 4px;
    overflow: hidden;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.05);
  }
  
  .device-progress {
    background: linear-gradient(90deg, #8fc760 0%, #75b04a 100%);
    height: 100%;
    border-radius: 4px;
    transition: width 0.4s ease;
  }
  
  /* Loading State */
  .loading-overlay {
    position: absolute;
    inset: 0;
    background: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: inherit;
  }
  
  .loading-spinner {
    width: 40px;
    height: 40px;
    border: 3px solid #f3f4f6;
    border-top-color: #8fc760;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  /* Animations */
  @keyframes spin {
    to { transform: rotate(360deg); }
  }
  
  @keyframes tooltipFade {
    from {
      opacity: 0;
      transform: translate(-50%, -90%);
    }
    to {
      opacity: 1;
      transform: translate(-50%, -100%);
    }
  }
  
  /* Responsive Design */
  @media (max-width: 1024px) {
    .analytics-wrapper {
      padding: 1.5rem;
    }
  
    .analytics-sections {
      grid-template-columns: 1fr;
    }
  
    .chart-container {
      padding: 1.5rem;
    }
  
    .action-tooltip {
      min-width: 200px;
    }
  }
  
  @media (max-width: 768px) {
    .analytics-wrapper {
      padding: 1rem;
      margin-top: 1.5rem;
    }
  
    .analytics-grid {
      grid-template-columns: 1fr;
    }
  
    .header-controls {
      flex-direction: column;
    }
  
    .card-value {
      font-size: 1.5rem;
    }
  
    .section {
      padding: 1.25rem;
    }
  
    .tooltip-label,
    .tooltip-value {
      font-size: 0.8125rem;
    }
  }