.admin-dashboard {
  padding: 24px;
  background-color: #f9f9f9;
  min-height: 100vh;
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px;
}

.dashboard-header h1 {
  margin-top: 50px;
  font-size: 2rem;
  color: #333;
  font-weight: bold;
}

.header-actions {
  display: flex;
  gap: 16px;
}

.settings-btn {
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  color: #333;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.settings-btn:hover {
  background-color: #f8f9fa;
  transform: translateY(-1px);
}

/* Analytics Cards */
.analytics-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
  gap: 24px;
  margin-bottom: 32px;
}

.analytics-card {
  background-color: #fff;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
  display: flex;
  align-items: center;
  gap: 16px;
  transition: transform 0.3s ease;
}

.analytics-card:hover {
  transform: translateY(-4px);
}

.card-icon {
  background-color: #e6f4ea;
  padding: 12px;
  border-radius: 50%;
  color: #8fc760;
}

.card-content h3 {
  font-size: 1rem;
  color: #666;
  margin-bottom: 4px;
}

.card-value {
  font-size: 1.5rem;
  font-weight: bold;
  color: #333;
}

/* Chart Section */
.chart-section {
  background-color: #fff;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 32px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.chart-section h2 {
  font-size: 1.5rem;
  color: #333;
  margin-bottom: 20px;
}

.chart-container {
  width: 100%;
  height: 300px;
}

/* Orders Section */
.orders-section {
  background-color: #fff;
  border-radius: 12px;
  padding: 24px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.orders-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.orders-header h2 {
  font-size: 1.5rem;
  color: #333;
}

.orders-actions {
  display: flex;
  gap: 16px;
  align-items: center;
}

.search-bar {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #f8f9fa;
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
}

.search-bar input {
  border: none;
  background: none;
  outline: none;
  font-size: 0.9rem;
  width: 200px;
}

.filter-select {
  padding: 8px 16px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  background-color: #fff;
  color: #333;
  font-size: 0.9rem;
  cursor: pointer;
}

.export-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #8fc760;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.export-btn:hover {
  background-color: #75b04a;
}

/* Enhanced Orders Table with Custom Scrollbar */
.orders-table-container {
  overflow-x: auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  scrollbar-width: thin;
  scrollbar-color: #8fc760 #f0f0f0;
  padding-bottom: 12px; /* Add space for scrollbar */
}

.orders-table-container::-webkit-scrollbar {
  height: 8px;
  background-color: #f0f0f0;
  border-radius: 4px;
}

.orders-table-container::-webkit-scrollbar-track {
  background: #f0f0f0;
  border-radius: 4px;
  margin: 0 8px;
}

.orders-table-container::-webkit-scrollbar-thumb {
  background: #8fc760;
  border-radius: 4px;
  border: 2px solid #f0f0f0;
  transition: background-color 0.3s ease;
}

.orders-table-container::-webkit-scrollbar-thumb:hover {
  background: #75b04a;
}

.orders-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  white-space: nowrap;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background-color: #fff;
}

.orders-table th {
  background-color: #f8f9fa;
  padding: 16px;
  text-align: left;
  font-weight: 600;
  color: #666;
  border-bottom: 2px solid #e2e8f0;
  border-right: 1px solid #e2e8f0;
}

.orders-table th:last-child {
  border-right: none;
}

.orders-table td {
  padding: 16px;
  border-bottom: 1px solid #e2e8f0;
  border-right: 1px solid #e2e8f0;
  color: #333;
  transition: background-color 0.2s ease;
  max-width: 300px;
  overflow: hidden;
}

/* Specific column widths */
.orders-table th:nth-child(1),
.orders-table td:nth-child(1) {
  width: 100px;
  min-width: 100px;
}

.orders-table th:nth-child(2),
.orders-table td:nth-child(2) {
  width: 150px;
  min-width: 150px;
}

.orders-table th:nth-child(3),
.orders-table td:nth-child(3) {
  width: 200px;
  min-width: 200px;
}

.orders-table th:nth-child(4),
.orders-table td:nth-child(4) {
  width: 300px;
  min-width: 300px;
  max-width: 300px;
}

.orders-table th:nth-child(5),
.orders-table td:nth-child(5) {
  width: 120px;
  min-width: 120px;
}

.orders-table th:nth-child(6),
.orders-table td:nth-child(6) {
  width: 120px;
  min-width: 120px;
}

.orders-table td:last-child {
  border-right: none;
}

.orders-table tr:hover td {
  background-color: #f8f9fa;
}

.orders-table tr:last-child td {
  border-bottom: none;
}

.action-btn {
  padding: 6px 12px;
  margin-right: 8px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  background-color: #fff;
  color: #333;
  cursor: pointer;
  transition: all 0.2s ease;
}

.action-btn:hover {
  background-color: #f8f9fa;
  transform: translateY(-1px);
}

.order-items-list {
  min-width: 200px;
  max-width: 100%;
  overflow: hidden;
}

.order-item {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 4px 0;
  font-size: 0.9rem;
  overflow: hidden;
}

.order-item:not(:last-child) {
  border-bottom: 1px solid #f0f0f0;
}

.item-quantity {
  font-weight: 600;
  color: #8fc760;
  min-width: 30px;
  white-space: nowrap;
}

.item-title {
  flex: 1;
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.item-price {
  color: #666;
  font-weight: 500;
  white-space: nowrap;
}

/* Loading Indicator */
.loading-indicator {
  text-align: center;
  padding: 20px;
  color: #666;
  font-size: 1.1rem;
}

/* Error Message */
.error-message {
  background-color: #fee2e2;
  color: #dc2626;
  padding: 12px;
  border-radius: 8px;
  margin-bottom: 16px;
  text-align: center;
}

.sent-out-btn {
  background-color: #8fc760;
  color: white;
  border: none;
}

.sent-out-btn:disabled {
  background-color: #d1d5db;
  cursor: not-allowed;
  transform: none;
}

.sent-out-btn:not(:disabled):hover {
  background-color: #75b04a;
}

.sent-indicator {
  background-color: #8fc760;
  color: white;
  padding: 6px 12px;
  border-radius: 6px;
  font-weight: bold;
  text-align: center;
}

.sent-status {
  display: flex;
  align-items: center;
  gap: 8px;
}

.sent-badge {
  background-color: #8fc760;
  color: white;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.875rem;
}

.edit-icon {
  cursor: pointer;
  color: #666;
  transition: color 0.2s;
}

.edit-icon:hover {
  color: #333;
}


/* Coupon Section Styles */
.coupon-section {
  background-color: #fff;
  border-radius: 12px;
  padding: 24px;
  margin-top: 32px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
}

.coupon-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
}

.coupon-header h2 {
  font-size: 1.5rem;
  color: #333;
  margin: 0;
}

.coupon-stats {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 16px;
  margin-bottom: 24px;
}

.stat-card {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
  display: flex;
  align-items: center;
  gap: 12px;
  transition: transform 0.2s ease;
}

.stat-card:hover {
  transform: translateY(-2px);
}

.stat-icon {
  background-color: #e6f4ea;
  padding: 10px;
  border-radius: 8px;
  color: #8fc760;
}

.stat-content {
  display: flex;
  flex-direction: column;
}

.stat-label {
  font-size: 0.875rem;
  color: #666;
}

.stat-value {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
}

.coupon-controls {
  display: flex;
  gap: 16px;
  align-items: center;
}


.coupon-table-container {
  overflow-x: auto;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.coupon-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
}

.coupon-table th {
  background-color: #f8f9fa;
  padding: 16px;
  text-align: left;
  font-weight: 600;
  color: #666;
  border-bottom: 2px solid #e2e8f0;
}

.coupon-table td {
  padding: 16px;
  border-bottom: 1px solid #e2e8f0;
  color: #333;
}

.coupon-code {
  font-family: monospace;
  background-color: #f3f4f6;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.875rem;
}

.status-badge {
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 0.875rem;
  font-weight: 500;
}

.status-badge.active {
  background-color: #e6f4ea;
  color: #059669;
}

.status-badge.used {
  background-color: #e5e7eb;
  color: #4b5563;
}

.expiration-date {
  font-size: 0.875rem;
}

.expiration-date.expired {
  color: #dc2626;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  
  .coupon-stats {
    grid-template-columns: 1fr;
  }
  
  .stat-card {
    padding: 12px;
  }
}


/* Responsive Design */
@media (max-width: 768px) {
  .analytics-cards {
    grid-template-columns: 1fr;
  }

  .orders-header {
    flex-direction: column;
    gap: 16px;
    align-items: stretch;
  }

  .orders-actions {
    flex-direction: column;
  }

  .search-bar {
    width: 100%;
  }

  .search-bar input {
    width: 100%;
  }

  .filter-select,
  .export-btn {
    width: 100%;
  }
}

.coupon-controls .export-btn {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #8fc760;
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  white-space: nowrap;
}

.coupon-controls .export-btn:hover {
  background-color: #75b04a;
  transform: translateY(-1px);
}


/* Update responsive styling */
@media (max-width: 768px) {
  .coupon-header {
    flex-direction: column;
    gap: 16px;
    align-items: stretch;
  }

  .coupon-controls {
    flex-direction: column;
    width: 100%;
  }
  
  .coupon-controls .search-bar,
  .coupon-controls .filter-select,
  .coupon-controls .export-btn {
    width: 100%;
  }
}

.portal-btn {
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #8fc760;
  border: none;
  border-radius: 8px;
  color: #fff;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
  box-shadow: 0 2px 4px rgba(143, 199, 96, 0.2);
}

.portal-btn:hover {
  background-color: #75b04a;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(143, 199, 96, 0.3);
}

.settings-btn {
  margin-top: 30px;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  background-color: #fff;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  color: #333;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.2s ease;
}

.settings-btn:hover {
  background-color: #f8f9fa;
  transform: translateY(-1px);
}