/* Cart Modal Overlay */
.cart-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

/* Cart Modal */
.cart-modal {
  background-color: #fff;
  width: 90%;
  max-width: 400px;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
  position: relative;
  animation: fadeIn 0.3s ease-in-out;
  max-height: 80vh;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
}

.cart-modal::-webkit-scrollbar {
  width: 8px;
}

.cart-modal::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
  margin: 4px;
}

.cart-modal::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: padding-box;
  transition: background-color 0.3s ease;
}

.cart-modal::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
  border: 2px solid transparent;
  background-clip: padding-box;
}

/* Scrollable Cart Items Container */
.cart-items-container {
  max-height: 50vh;
  overflow-y: auto;
  padding-right: 10px;
  margin-right: -10px;
  border-bottom: 1px solid #e2e8f0;
  margin-bottom: 20px;
  padding-bottom: 20px;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
}

.cart-items-container::-webkit-scrollbar {
  width: 8px;
}

.cart-items-container::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
  margin: 4px;
}

.cart-items-container::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: padding-box;
  transition: background-color 0.3s ease;
}

.cart-items-container::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
  border: 2px solid transparent;
  background-clip: padding-box;
}

/* Close Button */
.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #333;
}

/* Cart Title */
.cart-title {
  font-size: 1.8rem;
  font-weight: bold;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

/* Empty Cart Section */
.empty-cart {
  text-align: center;
  margin-top: 20px;
  color: #333;
}

.empty-cart-text {
  font-size: 1.2rem;
  font-weight: 600;
  margin-bottom: 10px;
}

.shop-link {
  display: inline-block;
  padding: 10px 20px;
  font-size: 1rem;
  font-weight: 600;
  color: #fff;
  background-color: #8fc760;
  text-decoration: none;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.shop-link:hover {
  background-color: #75b04a;
}

.shop-link:active {
  background-color: #639737;
  transform: scale(0.98);
}


/* Cart Item */
.cart-item {
  padding: 16px 0;
  border-bottom: 1px solid #f0f0f0;
}

.cart-item:last-child {
  border-bottom: none;
  padding-bottom: 0;
}

.cart-item-main {
  display: flex;
  gap: 16px;
  align-items: flex-start;
}

.cart-item-img {
  width: 80px;
  height: 80px;
  border-radius: 8px;
  object-fit: cover;
  flex-shrink: 0;
}

.cart-item-details {
  flex: 1;
  min-width: 0;
}

.cart-item-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 8px;
}

.cart-item-title {
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  flex: 1;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cart-item-price-container {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 8px;
}

.cart-item-controls {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.cart-item-price {
  font-size: 0.9rem;
  color: #666;
  margin: 0;
}

.cart-item-quantity {
  display: inline-flex;
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 6px;
  padding: 2px;
}

/* Quantity Controls */
.quantity-btn {
  background-color: white;
  border: 1px solid #e0e0e0;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  border-radius: 4px;
  transition: all 0.2s ease;
}

.quantity-btn:hover {
  background-color: #f8f8f8;
  border-color: #d0d0d0;
}

.quantity-btn:active {
  transform: scale(0.95);
}

.quantity {
  min-width: 32px;
  text-align: center;
  font-size: 0.9rem;
  font-weight: 500;
  color: #333;
}

/* Remove Button */
.remove-btn {
  grid-area: remove;
  background-color: #f0f0f5;
  border: none;
  padding: 6px 12px;
  color: #ff3b30;
  font-size: 0.8rem;
  font-weight: bold;
  border-radius: 12px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s, box-shadow 0.2s;
  margin-left: auto;
  height: 28px;
  display: flex;
  align-items: center;
  width: fit-content;
}

.remove-btn:hover {
  background-color: #ff3b30;
  color: #fff;
  transform: translateY(-2px);
  box-shadow: 0 4px 10px rgba(255, 59, 48, 0.3);
}

.remove-btn:active {
  transform: translateY(0);
  box-shadow: none;
}

.item-total {
  font-size: 1rem;
  font-weight: 600;
  color: #2d3748;
}

/* Enhanced Receipt Container */
.receipt-container {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 16px;
  margin: 20px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.receipt-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 12px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e2e8f0;
}

.receipt-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  font-size: 0.95rem;
  color: #4a5568;
}

.receipt-line.fee {
  padding-top: 12px;
  margin-top: 8px;
  border-top: 1px dashed #e2e8f0;
}

.receipt-line.total {
  padding-top: 12px;
  margin-top: 8px;
  border-top: 2px solid #e2e8f0;
  font-weight: 600;
  font-size: 1.1rem;
  color: #2d3748;
}

.receipt-subtitle {
  font-weight: 500;
  color: #4a5568;
}

/* Checkout Button */
.checkout-btn {
  display: block;
  width: 100%;
  padding: 12px;
  background-color: #333;
  color: #fff;
  font-size: 1.1rem;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  margin-top: 20px;
  transition: background 0.3s, opacity 0.3s;
}

.checkout-btn:hover:not(:disabled) {
  background-color: #555;
}

.checkout-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #666;
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.9);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* Price Tip */
.price-tip {
  background-color: #e0f7fa;
  color: #00796b;
  padding: 15px;
  border-radius: 8px;
  margin: 15px 0;
  text-align: center;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  animation: fadeIn 0.3s ease-in-out;
}

.price-tip p {
  margin: 5px 0;
  font-size: 0.95rem;
}

.add-three-pack-btn {
  padding: 8px 16px;
  font-size: 1rem;
  font-weight: bold;
  color: #fff;
  background-color: #00796b;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.3s;
  margin-top: 8px;
}

.add-three-pack-btn:hover {
  background-color: #004d40;
}

/* Cart Suggestions Section */
.cart-suggestions-container {
  margin-top: 24px;
  padding-top: 20px;
  border-top: 1px solid #e2e8f0;
}

.cart-suggestions-heading {
  font-size: 1.2rem;
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 16px;
  text-align: center;
}

.cart-suggestions-carousel {
  display: flex;
  overflow-x: auto;
  gap: 16px;
  padding: 4px 0 16px;
  margin: 0 -20px;
  padding-left: 20px;
  scroll-behavior: smooth;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.cart-suggestions-carousel::-webkit-scrollbar {
  display: none;
}

.cart-suggestion-card {
  flex: 0 0 auto;
  width: 140px;
  background-color: white;
  border-radius: 10px;
  overflow: hidden;
  transition: transform 0.2s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  border: 1px solid #e2e8f0;
}

.cart-suggestion-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.cart-suggestion-image-wrapper {
  width: 100%;
  height: 140px;
  background-color: #f7fafc;
  overflow: hidden;
}

.cart-suggestion-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.cart-suggestion-card:hover .cart-suggestion-image {
  transform: scale(1.05);
}

.cart-suggestion-content {
  padding: 12px;
}

.cart-suggestion-title {
  font-size: 0.9rem;
  font-weight: 600;
  color: #2d3748;
  margin: 0 0 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.cart-suggestion-price {
  font-size: 0.85rem;
  font-weight: 500;
  color: #4a5568;
  margin: 0 0 12px;
}

.cart-suggestion-add-btn {
  width: 100%;
  padding: 8px 12px;
  background-color: #8fc760;
  color: white;
  font-size: 0.8rem;
  font-weight: 600;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.cart-suggestion-add-btn:hover {
  background-color: #75b04a;
}

/* Responsive Adjustments */
@media (max-width: 360px) {
  .cart-item-main {
    gap: 12px;
  }
  
  .cart-item-img {
    width: 60px;
    height: 60px;
  }
  
  .cart-item-title {
    font-size: 0.9rem;
  }
  
  .cart-item-price {
    font-size: 0.8rem;
  }
  
  .quantity-btn {
    width: 24px;
    height: 24px;
    font-size: 0.9rem;
  }
  
  .remove-btn {
    font-size: 0.75rem;
  }

  .cart-suggestion-card {
    width: 120px;
  }
  
  .cart-suggestion-image-wrapper {
    height: 120px;
  }
  
  .cart-suggestion-content {
    padding: 10px;
  }
  
  .cart-suggestion-title {
    font-size: 0.85rem;
  }
  
  .cart-suggestion-price {
    font-size: 0.8rem;
    margin-bottom: 10px;
  }
  
  .cart-suggestion-add-btn {
    padding: 6px 10px;
    font-size: 0.75rem;
  }
}