.modal-overlay {
  position: fixed;
  inset: 0;
  background-color: rgba(0, 0, 0, 0.4);
  backdrop-filter: blur(4px);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-container {
  background: white;
  border-radius: 16px;
  width: 90%;
  max-width: 800px;
  margin: 2rem;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 10px 10px -5px rgba(0, 0, 0, 0.04);
  overflow: hidden;
  max-height: 90vh;
  display: flex;
  flex-direction: column;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 1.5rem 2rem;
  border-bottom: 1px solid #e5e7eb;
  background-color: #f9fafb;
}

.modal-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #111827;
}

.close-button {
  background: transparent;
  border: none;
  color: #6b7280;
  padding: 0.5rem;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s;
}

.close-button:hover {
  background-color: #f3f4f6;
  color: #111827;
}

.tab-navigation {
  display: flex;
  padding: 0 1rem;
  border-bottom: 1px solid #e5e7eb;
  background-color: #ffffff;
  overflow-x: auto;
  scrollbar-width: none;
  height: 5rem;
}

.tab-navigation::-webkit-scrollbar {
  display: none;
}

.tab-button {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 1rem 1.5rem;
  color: #6b7280;
  border: none;
  background: none;
  font-weight: 500;
  cursor: pointer;
  white-space: nowrap;
  transition: all 0.2s;
  border-bottom: 2px solid transparent;
  margin-bottom: -1px;
}

.tab-button.active {
  color: #8fc760;
  border-bottom: 2px solid #8fc760;
}

.tab-button:hover:not(.active) {
  color: #111827;
  background-color: #f9fafb;
}

.modal-content {
  padding: 2rem;
  overflow-y: auto;
}

.section-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #111827;
  margin-bottom: 1.5rem;
}

.settings-grid {
  display: grid;
  gap: 1.5rem;
}

.form-group {
  margin-bottom: 1.5rem;
}

.form-label {
  display: block;
  font-size: 0.875rem;
  font-weight: 500;
  color: #374151;
  margin-bottom: 0.5rem;
}

.form-input,
.form-select {
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  font-size: 0.875rem;
  color: #111827;
  background-color: white;
  transition: all 0.2s;
}

.form-input:hover,
.form-select:hover {
  border-color: #8fc760;
}

.form-input:focus,
.form-select:focus {
  outline: none;
  border-color: #8fc760;
  box-shadow: 0 0 0 3px rgba(143, 199, 96, 0.2);
}

.checkbox-wrapper {
  display: flex;
  align-items: center;
  margin-bottom: 0.75rem;
}

.checkbox-input {
  width: 1rem;
  height: 1rem;
  margin-right: 0.5rem;
  accent-color: #8fc760;
}

.checkbox-label {
  font-size: 0.875rem;
  color: #374151;
}

.submit-button {
  background-color: #8fc760;
  color: white;
  padding: 0.75rem 1.5rem;
  border-radius: 8px;
  font-weight: 600;
  border: none;
  cursor: pointer;
  width: 100%;
  transition: all 0.2s;
}

.submit-button:hover {
  background-color: #7ab34d;
}

.submit-button:disabled {
  background-color: #e5e7eb;
  cursor: not-allowed;
}

.error-message,
.success-message {
  padding: 1rem;
  border-radius: 8px;
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.error-message {
  background-color: #fef2f2;
  color: #dc2626;
  border: 1px solid #fecaca;
}

.success-message {
  background-color: #f0fdf4;
  color: #16a34a;
  border: 1px solid #dcfce7;
}

@media (max-width: 640px) {
  .modal-container {
    margin: 1rem;
    width: 95%;
  }
  
  .modal-header,
  .modal-content {
    padding: 1rem;
  }
  
  .tab-button {
    padding: 0.75rem 1rem;
  }
}