.admin-login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: #f9f9f9;
    padding: 20px;
  }
  
  .admin-login-card {
    background-color: white;
    padding: 40px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 400px;
  }
  
  .admin-login-card h2 {
    color: #333;
    text-align: center;
    margin-bottom: 30px;
    font-size: 1.8rem;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group input {
    width: 92%;
    padding: 12px;
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    font-size: 1rem;
    transition: border-color 0.3s ease;
  }
  
  .form-group input:focus {
    outline: none;
    border-color: #8fc760;
  }
  
  .login-button {
    width: 100%;
    padding: 12px;
    background-color: #8fc760;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .login-button:hover {
    background-color: #75b04a;
  }
  
  .error-message {
    background-color: #fee2e2;
    color: #dc2626;
    padding: 12px;
    border-radius: 8px;
    margin-bottom: 20px;
    text-align: center;
  }

  .password-input {
    position: relative;
  }
  
  .password-toggle {
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    background-color: transparent;
    border: none;
    cursor: pointer;
    color: #8fc760;
    transition: color 0.3s ease;
  }
  
  .password-toggle:hover {
    color: #75b04a;
  }