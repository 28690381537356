/* Add Space Above FAQ Container */
.faq-container {
    width: 90%;
    max-width: 800px;
    margin: 80px auto 0;
    padding: 40px 20px;
    background-color: #f9f9f9;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, 'Helvetica Neue', Arial, sans-serif;
    color: #333;
  }
  
/* Title */
.faq-title {
    font-size: 2.4rem;
    font-weight: 600;
    color: #333;
    text-align: center;
    margin-bottom: 20px;
    font-family: 'Helvetica Neue', Arial, sans-serif;
  }

/* Intro Text */
.faq-intro {
    font-size: 1.1rem;
    text-align: center;
    color: #666;
    max-width: 600px;
    margin: 0 auto 40px;
    line-height: 1.6;
  }
  
/* FAQ List */
.faq-list {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  
/* FAQ Item */
.faq-item {
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.05);
    transition: box-shadow 0.3s ease, transform 0.3s ease;
  }

.faq-item:hover {
    transform: scale(1.02);
    box-shadow: 0 6px 16px rgba(0, 0, 0, 0.1);
  }
  
/* FAQ Question */
.faq-question {
    font-size: 1.6rem;
    font-weight: 500;
    color: #1c1c1e;
    margin-bottom: 12px;
  }
  
/* FAQ Answer */
.faq-answer {
    font-size: 1.1rem;
    color: #555;
    line-height: 1.6;
  }
  
/* Responsive Design for Mobile */
@media (max-width: 768px) {
  .faq-container {
    padding: 20px;
    margin-top: 60px;
  }

  .faq-title {
    font-size: 2rem;
  }

  .faq-question {
    font-size: 1.4rem;
  }

  .faq-answer {
    font-size: 1rem;
  }
}
