/* Contact Container */
.contact-container {
  max-width: 800px;
  margin: 100px auto 50px;
  padding: 40px;
  background-color: #fafafa;
  border-radius: 16px;
  box-shadow: 0 6px 24px rgba(0, 0, 0, 0.08);
  text-align: center;
  animation: fadeIn 0.5s ease-in-out;
}

/* Title & Subtitle */
.contact-title {
  font-size: 2.2rem;
  font-weight: 600;
  margin-bottom: 12px;
  color: #333;
  letter-spacing: 0.5px;
}

.contact-subtitle {
  font-size: 1.1rem;
  margin-bottom: 35px;
  color: #666;
  line-height: 1.6;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

/* Form Styles */
.contact-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.form-group {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  transition: transform 0.3s ease;
}

.form-group:hover {
  transform: translateY(-2px);
}

.form-label {
  margin-bottom: 8px;
  font-size: 1rem;
  color: #333;
  font-weight: 500;
  transition: color 0.3s ease;
}

.form-group:hover .form-label {
  color: #8fc760;
}

.form-input,
.form-select,
.form-textarea {
  max-width: 100%;
  width: 100%;
  padding: 14px 16px;
  border: 2px solid #f0f0f0;
  border-radius: 12px;
  font-size: 1rem;
  outline: none;
  transition: all 0.3s ease;
  background-color: white;
  color: #333;
  box-sizing: border-box;
}

.form-textarea {
  min-height: 150px;
  resize: vertical;
}

.form-input:hover,
.form-select:hover,
.form-textarea:hover {
  border-color: #e0e0e0;
  box-shadow: 0 2px 8px rgba(143, 199, 96, 0.1);
}

.form-input:focus,
.form-select:focus,
.form-textarea:focus {
  border-color: #8fc760;
  box-shadow: 0 0 0 4px rgba(143, 199, 96, 0.15);
}

/* Dropdown Icon Styling */
.dropdown-wrapper {
  position: relative;
  width: 100%;
  cursor: pointer;
}

.form-select {
  appearance: none;
  padding-right: 45px;
  cursor: pointer;
  background-color: white;
}

.dropdown-icon {
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
  pointer-events: none;
  width: 20px;
  height: auto;
  opacity: 0.7;
  transition: opacity 0.3s ease;
}

.dropdown-wrapper:hover .dropdown-icon {
  opacity: 1;
}

/* Submit Button */
.submit-btn {
  padding: 16px 32px;
  font-size: 1.1rem;
  font-weight: 600;
  background-color: #8fc760;
  color: white;
  border: none;
  border-radius: 12px;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-top: 8px;
}

.submit-btn:hover {
  background-color: #75b04a;
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(143, 199, 96, 0.3);
}

.submit-btn:active {
  transform: translateY(0);
  box-shadow: 0 2px 6px rgba(143, 199, 96, 0.2);
}

/* Newsletter Styles */
.newsletter-section {
  margin: 28px 0;
}

.newsletter-container {
  background-color: white;
  padding: 24px;
  border-radius: 12px;
  border: 2px solid #f0f0f0;
  transition: all 0.3s ease;
}

.newsletter-container:hover {
  border-color: #8fc760;
  box-shadow: 0 2px 12px rgba(143, 199, 96, 0.15);
}

.newsletter-header {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 8px;
}

.newsletter-icon {
  width: 24px;
  height: 24px;
  color: #8fc760;
}

.newsletter-title {
  font-size: 1.125rem;
  font-weight: 500;
  color: #333;
  margin: 0;
}

.newsletter-description {
  font-size: 0.875rem;
  color: #666;
  margin-bottom: 16px;
  line-height: 1.5;
}

.newsletter-toggle {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}

.toggle-wrapper {
  position: relative;
  width: 56px;
  height: 28px;
}

.toggle-input {
  position: absolute;
  opacity: 0;
  width: 0;
  height: 0;
}

.toggle-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #e5e5e5;
  transition: 0.3s;
  border-radius: 28px;
}

.toggle-button {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  transition: 0.3s;
  border-radius: 50%;
}

.toggle-input:checked + .toggle-slider {
  background-color: #8fc760;
}

.toggle-input:checked ~ .toggle-button {
  transform: translateX(28px);
}

.toggle-label {
  color: #333;
  font-size: 0.875rem;
  transition: color 0.2s;
}

.newsletter-toggle:hover .toggle-label {
  color: #000;
}

/* Notification Bubble */
.notification-bubble {
  position: fixed;
  top: 24px;
  left: 50%;
  transform: translateX(-50%);
  padding: 16px 24px;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15);
  animation: fadeInOut 5s ease-in-out;
  z-index: 1000;
  max-width: 320px;
  text-align: center;
}

.notification-bubble.success {
  background-color: #8fc760;
  color: white;
}

.notification-bubble.error {
  background-color: #e74c3c;
  color: white;
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInOut {
  0% {
    opacity: 0;
    transform: translate(-50%, -20px);
  }
  10% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  90% {
    opacity: 1;
    transform: translate(-50%, 0);
  }
  100% {
    opacity: 0;
    transform: translate(-50%, -20px);
  }
}

/* Responsive Design */
@media (max-width: 768px) {
  .contact-container {
    padding: 24px;
    margin: 60px auto 30px;
  }

  .contact-title {
    font-size: 1.8rem;
  }

  .contact-subtitle {
    font-size: 1rem;
    margin-bottom: 25px;
  }

  .form-input,
  .form-select,
  .form-textarea {
    padding: 12px 14px;
    font-size: 0.95rem;
  }

  .submit-btn {
    font-size: 1rem;
    padding: 14px 28px;
  }

  .newsletter-container {
    padding: 20px;
  }
  
  .newsletter-header {
    gap: 8px;
  }
  
  .newsletter-title {
    font-size: 1rem;
  }
  
  .newsletter-description {
    font-size: 0.8125rem;
  }
}