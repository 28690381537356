/* NotFound.css */
.not-found-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f9f9f9;
    padding: 1rem;
  }
  
  .not-found-content {
    max-width: 28rem;
    width: 100%;
    background-color: #ffffff;
    border-radius: 0.75rem;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 2rem;
    text-align: center;
    transition: transform 0.3s ease;
  }
  
  .not-found-circle {
    width: 10rem;
    height: 10rem;
    margin: 0 auto 2rem;
    border-radius: 50%;
    background-color: #f0faf0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .not-found-number {
    font-size: 4.5rem;
    font-weight: bold;
    color: #8fc760;
  }
  
  .not-found-title {
    font-size: 1.875rem;
    font-weight: bold;
    color: #333333;
    margin-bottom: 1rem;
  }
  
  .not-found-message {
    color: #666666;
    margin-bottom: 2rem;
    line-height: 1.5;
  }
  
  /* Timer and Progress Bar Styles */
  .redirect-timer {
    margin: 1.5rem 0;
    width: 100%;
  }
  
  .progress-bar-container {
    width: 100%;
    height: 6px;
    background-color: #f0f0f0;
    border-radius: 3px;
    overflow: hidden;
    margin-bottom: 0.75rem;
  }
  
  .progress-bar {
    height: 100%;
    background: linear-gradient(
      90deg,
      #8fc760 0%,
      #a5d583 50%,
      #8fc760 100%
    );
    background-size: 200% 100%;
    border-radius: 3px;
    transition: width 1s linear;
    animation: gradientMove 2s linear infinite;
  }
  
  .timer-text {
    font-size: 0.875rem;
    color: #666666;
    margin-top: 0.5rem;
    font-weight: 500;
    animation: pulse 2s infinite;
  }
  
  .not-found-buttons {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    justify-content: center;
  }
  
  .not-found-button {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 0.75rem 1.5rem;
    border-radius: 0.5rem;
    font-weight: 600;
    transition: all 0.2s ease;
    position: relative;
    overflow: hidden;
  }
  
  .button-icon {
    width: 1.25rem;
    height: 1.25rem;
    margin-right: 0.5rem;
  }
  
  .back-button {
    background-color: #ffffff;
    border: 2px solid #8fc760;
    color: #8fc760;
  }
  
  .back-button:hover {
    background-color: #f0faf0;
    transform: translateY(-1px);
  }
  
  .home-button {
    background-color: #8fc760;
    border: none;
    color: #ffffff;
  }
  
  .home-button:hover {
    background-color: #75b04a;
    transform: translateY(-1px);
  }
  
  /* Button after effect */
  .not-found-button::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 0;
    height: 0;
    background: rgba(255, 255, 255, 0.2);
    border-radius: 50%;
    transform: translate(-50%, -50%);
    transition: width 0.3s, height 0.3s;
  }
  
  .not-found-button:active::after {
    width: 200px;
    height: 200px;
  }
  
  /* Enhance accessibility */
  .not-found-button:focus {
    outline: none;
    box-shadow: 0 0 0 3px rgba(143, 199, 96, 0.3);
  }
  
  /* Content hover effect */
  .not-found-content:hover {
    transform: translateY(-5px);
  }
  
  /* Animations */
  @keyframes gradientMove {
    0% {
      background-position: 100% 0;
    }
    100% {
      background-position: -100% 0;
    }
  }
  
  @keyframes pulse {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0.7;
    }
    100% {
      opacity: 1;
    }
  }
  
  /* Media Queries */
  @media (min-width: 640px) {
    .not-found-buttons {
      flex-direction: row;
    }
    
    .not-found-button {
      min-width: 140px;
    }
  }
  
  /* Mobile optimization for progress bar */
  @media (max-width: 640px) {
    .progress-bar-container {
      height: 4px;
    }
    
    .timer-text {
      font-size: 0.8rem;
    }
  }