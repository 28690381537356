/* Global Styles for Blog */
html, body {
  overflow-x: hidden; /* Hide horizontal overflow */
}



/* Blog Container */
.blog-container {
    max-width: 1200px;
    margin: 100px auto 50px;
    padding: 20px;
    text-align: center;
    animation: fadeIn 0.5s ease-in-out;
  }
  
  /* Title & Subtitle */
  .blog-title {
    font-size: 2.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .blog-subtitle {
    font-size: 1.2rem;
    color: #555;
    margin-bottom: 40px;
    line-height: 1.5;
  }
  
  /* Blog Grid */
  .blog-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    gap: 30px;
  }
  
  /* Blog Card */
  .blog-card {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    cursor: pointer;
    transition: transform 0.3s, box-shadow 0.3s;
  }
  
  .blog-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
  }
  
  /* Blog Image */
  .blog-image {
    width: 100%;
    height: 150px; /* Reduced height */
    object-fit: cover;
    border-bottom: 2px solid #8fc760; /* Add a border to emphasize the image */
  }
  
  /* Blog Content */
  .blog-content {
    padding: 20px;
  }
  
  .blog-post-title {
    font-size: 1.4rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 10px;
  }
  
  .blog-overview {
    font-size: 1rem;
    color: #666;
    margin-bottom: 20px;
    line-height: 1.4;
  }
  
  /* Read More Button */
  .read-more-btn {
    padding: 10px 20px;
    font-size: 1rem;
    background-color: #8fc760;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .read-more-btn:hover {
    background-color: #75b04a;
    transform: translateY(-3px);
  }
  
  .read-more-btn:active {
    transform: translateY(0);
  }
  
  /* Animation */
  @keyframes fadeIn {
    from {
      opacity: 0;
      transform: translateY(-10px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  

  /* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    overflow: hidden; /* Prevent scrolling on overlay */
  }
  
  .modal-content {
    background-color: #fff;
    border-radius: 12px;
    max-width: 600px;
    max-height: 80vh; /* Limit modal height */
    padding: 30px;
    text-align: left;
    position: relative;
    animation: fadeIn 0.3s ease-in-out;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.2);
    overflow-y: auto; /* Allow scrolling inside the modal */
  }
  
  .close-btn {
    position: absolute;
    top: 15px;
    right: 20px;
    font-size: 1.5rem;
    color: #333;
    cursor: pointer;
  }
  
  .modal-image {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
    border-radius: 8px;
    object-fit: cover;
  }
  
  .modal-title {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .modal-text {
    font-size: 1.1rem;
    color: #666;
    line-height: 1.6;
  }
  