/* Blog Modal Styles */
.blog-modal-overlay {
    position: fixed;
    inset: 0;
    background-color: rgba(0, 0, 0, 0.4);
    backdrop-filter: blur(4px);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 9999;
    padding: 20px;
    overflow: hidden;
  }
  
  .blog-modal {
    background: white;
    border-radius: 16px;
    width: 90%;
    max-width: 800px;
    max-height: 90vh;
    overflow-y: auto;
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    position: relative;
  }
  
  .blog-modal::-webkit-scrollbar {
    width: 8px;
  }
  
  .blog-modal::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 4px;
  }
  
  .blog-modal::-webkit-scrollbar-thumb {
    background: #8fc760;
    border-radius: 4px;
  }
  
  .blog-modal-header {
    position: sticky;
    top: 0;
    z-index: 10;
    background-color: white;
    border-bottom: 1px solid #e5e7eb;
    padding: 1.5rem 2rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .blog-modal-header h2 {
    font-size: 1.5rem;
    font-weight: 600;
    color: #111827;
    margin: 0;
  }
  
  .close-button {
    background: transparent;
    border: none;
    color: #6b7280;
    padding: 0.5rem;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s;
  }
  
  .close-button:hover {
    background-color: #f3f4f6;
    color: #111827;
  }
  
  .blog-modal-content {
    padding: 2rem;
  }
  
  .blog-meta {
    display: flex;
    gap: 1.5rem;
    margin-bottom: 2rem;
    font-size: 0.875rem;
    color: #6b7280;
  }
  
  .blog-content {
    font-size: 1.125rem;
    line-height: 1.75;
    color: #374151;
  }
  
  .blog-content h3 {
    color: #8fc760;
    font-size: 1.5rem;
    margin: 2rem 0 1rem;
  }
  
  .blog-content p {
    margin-bottom: 1.5rem;
  }
  
  .blog-content ul {
    margin: 1.5rem 0;
    padding-left: 1.5rem;
  }
  
  .blog-content li {
    margin-bottom: 0.5rem;
  }
  
  @media (max-width: 640px) {
    .blog-modal-content {
      padding: 1.5rem;
    }
  
    .blog-meta {
      flex-direction: column;
      gap: 0.5rem;
    }
  
    .blog-content {
      font-size: 1rem;
    }
  }