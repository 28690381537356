/* General Container */
.education-container {
  padding: 40px 20px;
  max-width: 1200px;
  margin: 0 auto;
  color: #333;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
}

/* Hero Section */
.education-hero-section {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #f9f9f9;
  padding: 60px 20px;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  margin-bottom: 60px;
}

.education-hero-message {
  max-width: 800px;
}

.education-hero-description {
  font-size: 1.6rem;
  color: #666;
  line-height: 1.8;
  font-weight: 400;
  font-style: italic;
  margin: 0 auto;
  padding: 0 10px;
}

/* Section Titles */
.section-title {
  font-size: 2.2rem;
  color: #8fc760;
  font-weight: 600;
  margin-top: 30px;
  margin-bottom: 15px;
  text-align: center;
}

/* Section Descriptions */
.section-description {
  font-size: 1.2rem;
  color: #555;
  line-height: 1.7;
  text-align: center;
  max-width: 900px;
  margin: 0 auto 40px;
  padding: 0 20px;
}

/* Blog Section */
.blog-section {
  margin-top: 50px;
  padding-top: 20px;
  border-top: 1px solid #ddd;
}

.blog-posts {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.blog-post {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.blog-post:hover {
  transform: translateY(-5px);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
}

.blog-post-title {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 10px;
}

.blog-post-excerpt {
  font-size: 1rem;
  color: #555;
  line-height: 1.6;
  margin-bottom: 15px;
}

.read-more-btn {
  font-size: 1rem;
  color: #8fc760;
  background: none;
  border: none;
  font-weight: bold;
  cursor: pointer;
  text-decoration: underline;
  transition: color 0.3s ease;
}

.read-more-btn:hover {
  color: #6fa544;
}

/* Responsive Design */
@media (max-width: 768px) {
  .education-hero-description {
    font-size: 1.3rem;
    padding: 0 15px;
  }

  .section-title {
    font-size: 1.8rem;
  }

  .section-description {
    font-size: 1rem;
    line-height: 1.5;
  }

  .blog-post-title {
    font-size: 1.3rem;
  }

  .blog-post-excerpt {
    font-size: 0.9rem;
  }
}


/* Purchase Links Section */
.purchase-links-section {
  padding: 40px 20px;
  background-color: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
  margin: 40px auto;
}

.purchase-links {
  list-style-type: none;
  padding: 0;
  margin: 20px 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 50px;
}

.purchase-link {
  font-size: 1.2rem;
  color: #8fc760;
  text-decoration: none;
  font-weight: bold;
  border: 2px solid #8fc760;
  padding: 10px 20px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.purchase-link:hover {
  background-color: #8fc760;
  color: #fff;
  transform: translateY(-3px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

/* Responsive Adjustments */
@media (max-width: 768px) {
  .purchase-links {
    flex-direction: column;
    gap: 50px;
  }

  .purchase-link {
    font-size: 1rem;
    padding: 10px 15px;
  }
}

