/* Checkout Modal Overlay */
.checkout-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2000;
}

/* Checkout Modal */
.checkout-modal {
  background-color: #fff;
  width: 90%;
  max-width: 600px;
  max-height: 90vh;
  padding: 30px;
  border-radius: 14px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.25);
  position: relative;
  animation: fadeIn 0.4s ease-in-out;
  overflow-y: auto;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.3) transparent;
}

.checkout-modal::-webkit-scrollbar {
  width: 8px;
}

.checkout-modal::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 10px;
  margin: 4px;
}

.checkout-modal::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.2);
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: padding-box;
  transition: background-color 0.3s ease;
}

.checkout-modal::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.3);
}

/* Close Button */
.close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #888;
  transition: color 0.3s;
}

.close-btn:hover {
  color: #333;
}

/* Checkout Title */
.checkout-title {
  font-size: 2rem;
  font-weight: 600;
  text-align: center;
  margin-bottom: 24px;
  color: #333;
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

/* Checkout Form */
.checkout-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.checkout-form-section {
  background-color: #f8f9fa;
  border-radius: 12px;
  padding: 20px;
}

.section-title {
  font-size: 1.2rem;
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e2e8f0;
}

.checkout-input {
  width: 95%;
  padding: 14px;
  margin-bottom: 12px;
  border: 1px solid #ddd;
  border-radius: 10px;
  font-size: 1rem;
  transition: border-color 0.3s;
}

.checkout-input:focus {
  border-color: #333;
  outline: none;
}

.card-element {
  padding: 14px;
  border: 1px solid #ddd;
  border-radius: 10px;
  margin-top: 12px;
  font-size: 1rem;
  background-color: white;
}

/* Checkout Section Styles */
.checkout-section {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin: 24px 0;
}

.checkout-summary-title {
  font-size: 1.1rem;
  font-weight: 600;
  color: #2d3748;
  margin-bottom: 16px;
  padding-bottom: 8px;
  border-bottom: 1px solid #e2e8f0;
}

.checkout-items-list {
  margin-bottom: 20px;
}

.checkout-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 0;
  border-bottom: 1px solid #e2e8f0;
}

.checkout-item:last-child {
  border-bottom: none;
}

.checkout-item-details {
  display: flex;
  align-items: center;
  gap: 12px;
  flex: 1;
}

.checkout-item-img {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  object-fit: cover;
}

.checkout-item-info {
  flex: 1;
  min-width: 0;
}

.checkout-item-title {
  font-size: 1rem;
  font-weight: 600;
  color: #2d3748;
  margin: 0 0 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.checkout-item-quantity {
  font-size: 0.9rem;
  color: #666;
  margin: 0;
}

.checkout-item-total {
  font-size: 1rem;
  font-weight: 600;
  color: #2d3748;
}

.checkout-summary-container {
  margin-top: 20px;
  padding-top: 16px;
  border-top: 1px solid #e2e8f0;
}

.checkout-summary-line {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 0;
  font-size: 0.95rem;
  color: #4a5568;
}

.checkout-summary-line.subtotal {
  font-weight: 500;
}

.checkout-summary-line.fee {
  padding-top: 12px;
  margin-top: 8px;
  border-top: 1px dashed #e2e8f0;
}

.checkout-summary-line.discount {
  color: #48bb78;
  font-weight: 500;
}

.checkout-summary-line.total {
  padding-top: 12px;
  margin-top: 8px;
  border-top: 2px solid #e2e8f0;
  font-weight: 600;
  font-size: 1.1rem;
  color: #2d3748;
}

/* Discount Section */
.discount-section {
  display: flex;
  gap: 8px;
  margin: 16px 0;
}

.checkout-input.discount-input {
  flex-grow: 1;
  margin-bottom: 0;
}

.checkout-apply-btn {
  padding: 14px 20px;
  background-color: #8fc760;
  color: white;
  font-size: 1rem;
  font-weight: 500;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.checkout-apply-btn:hover:not(:disabled) {
  background-color: #75b04a;
  transform: translateY(-1px);
}

.checkout-apply-btn:disabled {
  background-color: #e2e8f0;
  cursor: not-allowed;
}

/* Submit Button */
.checkout-submit-btn {
  width: 100%;
  padding: 16px;
  background-color: #333;
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  margin-top: 24px;
  transition: all 0.3s ease;
}

.checkout-submit-btn:hover:not(:disabled) {
  background-color: #555;
  transform: translateY(-1px);
}

.checkout-submit-btn:disabled {
  opacity: 0.5;
  cursor: not-allowed;
}

/* Messages */
.success-message {
  color: #48bb78;
  font-size: 0.9rem;
  margin-top: 8px;
  text-align: center;
}

.error-message {
  color: #e53e3e;
  font-size: 0.9rem;
  margin-top: 8px;
  text-align: center;
}

/* Receipt Modal */
.receipt-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.65);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2100;
}

.receipt-content {
  position: relative;
  background-color: #fff;
  width: 92%;
  max-width: 500px;
  padding: 30px;
  border-radius: 14px;
  text-align: center;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.25);
  font-family: 'Helvetica Neue', Arial, sans-serif;
}

.receipt-content h2 {
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
  font-weight: 600;
}

.receipt-cart-summary {
  list-style: none;
  padding: 0;
  margin: 16px 0 24px 0;
  border-top: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  padding-top: 12px;
  padding-bottom: 12px;
}

.receipt-cart-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 12px;
  font-size: 1rem;
  color: #666;
}

.receipt-item-img {
  width: 45px;
  height: 45px;
  border-radius: 10px;
  margin-right: 12px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  cursor: pointer;
}

.receipt-item-img:hover {
  transform: scale(1.1);
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.25);
}

.receipt-total {
  font-weight: 600;
  color: #333;
  font-size: 1.3rem;
  margin-top: 18px;
}

.receipt-close-btn {
  padding: 14px 24px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 10px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  margin-top: 24px;
  transition: background-color 0.3s;
}

.receipt-close-btn:hover {
  background-color: #555;
}

.receipt-print-icon {
  position: absolute;
  top: 2px;
  right: -2px;
  width: 64px;
  height: auto;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 0.3s ease, transform 0.2s ease;
  z-index: 1;
}

.receipt-print-icon:hover {
  opacity: 1;
  transform: scale(1.05);
}

/* Fullscreen Image Overlay */
.fullscreen-image-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.75);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2200;
}

.fullscreen-image-overlay img {
  max-width: 90%;
  max-height: 90%;
  border-radius: 12px;
  box-shadow: 0 8px 24px rgba(0, 0, 0, 0.5);
}

.fullscreen-image-overlay .close-fullscreen-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 2rem;
  color: #fff;
  background: none;
  border: none;
  cursor: pointer;
}

.back-btn {
  display: flex;
  align-items: center;
  background-color: #f1f3f5;
  color: #495057;
  border: none;
  border-radius: 8px;
  padding: 12px 16px;
  font-size: 1rem;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.back-btn:hover {
  background-color: #e9ecef;
  color: #343a40;
}

.back-btn-icon {
  font-size: 1.2rem;
  margin-right: 8px;
  position: relative;
  bottom: 3px;
}

.back-btn-text {
  font-family: 'Montserrat', sans-serif;
}

/* Animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Updated Responsive Design */
@media (max-width: 640px) {
  .checkout-modal {
    padding: 20px;
    max-height: 85vh;
    width: 95%;
  }

  .checkout-title {
    font-size: 1.6rem;
    margin-bottom: 20px;
  }

  .checkout-form-section {
    padding: 15px;
  }

  .input-row {
    flex-direction: column;
    gap: 0;
  }

  .input-row .checkout-input {
    width: 95% !important;
    margin-bottom: 12px;
  }

  .input-row .checkout-input:last-child {
    margin-bottom: 0;
  }

  .checkout-input {
    padding: 12px;
    font-size: 0.95rem;
  }

  .checkout-item-img {
    width: 50px;
    height: 50px;
  }

  .checkout-item-title {
    font-size: 0.9rem;
  }

  .checkout-summary-line {
    font-size: 0.9rem;
  }

  .checkout-submit-btn {
    padding: 14px;
    font-size: 1rem;
  }

  .receipt-content {
    padding: 20px;
  }

  .receipt-content h2 {
    font-size: 1.6rem;
  }

  .discount-section {
    flex-direction: column;
    gap: 12px;
  }

  .checkout-apply-btn {
    width: 100%;
    padding: 12px;
  }
}

@media (max-width: 480px) {
  .checkout-modal {
    padding: 15px;
    width: 100%;
    margin: 0;
    border-radius: 0;
    max-height: 100vh;
  }

  .checkout-form-section {
    padding: 12px;
  }

  .checkout-input {
    padding: 10px;
    font-size: 0.9rem;
  }

  .card-element {
    padding: 12px;
    font-size: 0.9rem;
  }
}